import React, { useContext, useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import Footer from "./Footer";
import Appeal from "./Appeal";
import mobilechat from "../images/mobilechat.svg";
import completed from "../images/completed.svg";
import cancelled from "../images/cross.png";
import { GlobalContext } from "../contexts/GlobalContext";
import SkeletonLoader from "./SkeletonLoader";
import { formatCurrency } from "../utils/fx";

const OrderSuccess = ({
  setActivePage,
  setModalType,
  resetForm,
  orderReference,
  setDisputeStatus,
  setOrderReference,
  setDisputeReference,
}) => {
  // Start Context
  const { getOrderRequest } = useContext(GlobalContext);
  // End Context

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    let res = await getOrderRequest(orderReference);
    setData(res?.data);
    res?.data && setLoading(false);
  };

  // const getNewData = async () => {
  //   let res = await getOrderRequest(orderReference);
  //   setData(res?.data);
  // };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   getNewData();
  //   const intervalCall = setInterval(() => {
  //     getNewData();
  //   }, 1000); //1 seconds
  //   return () => {
  //     clearInterval(intervalCall);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="margin-top-xs  ">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              <p className="receiving-details title ">
                {data?.orderType === "Buy" &&
                  `Buy ${data?.receiveInCurrencyCode}`}
                {data?.orderType === "Sell" &&
                  `Sell ${data?.payInCurrencyCode}`}
                {data?.orderType === "Swap" &&
                  `Swap ${data?.payInCurrencyCode}`}
              </p>

              <div className="order-card-lg p-4">
                <div className="row">
                  <div className="col-md-6 buycrypto p-3">
                    {" "}
                    {loading ? (
                      <SkeletonLoader />
                    ) : (
                      <>
                        <div className="text-center">
                          {data?.orderStatus === "Cancelled" ? (
                            <img src={cancelled} height={79} alt="cancelled" />
                          ) : (
                            <img src={completed} alt="completed" />
                          )}

                          {data?.orderType === "Buy" ? (
                            <>
                              {" "}
                              <h6 className="mt-3 mb-2">
                                {data?.orderStatus === "Completed" &&
                                  "Buy Order Completed"}
                                {data?.orderStatus === "Cancelled" &&
                                  "Buy Order Canceled"}
                                {data?.orderStatus === "FundReceivedFromUser" &&
                                  "Payment Confirmed"}
                                {data?.orderStatus === "FundedProvider" &&
                                  "Awaiting Payment Confirmation"}
                              </h6>
                              {(data?.orderStatus === "FundReceivedFromUser" ||
                                data?.orderStatus === "FundedProvider") && (
                                <>
                                  {" "}
                                  <p className="mb-2">
                                    {data?.payableAmount}
                                    <span>&nbsp;{data?.payInCurrencyCode}</span>
                                  </p>
                                </>
                              )}
                              {(data?.orderStatus === "Completed" ||
                                data?.orderStatus === "Cancelled") && (
                                <p className="mb-2">
                                  {data?.receivableAmount}
                                  <span>
                                    &nbsp;{data?.receiveInCurrencyCode}
                                  </span>
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <h6 className="mt-3 mb-2">
                                {data?.orderStatus === "Completed" &&
                                  `${data?.orderType} Order Completed`}
                                {data?.orderStatus === "Cancelled" &&
                                  `${data?.orderType} Order Canceled`}
                                {data?.orderStatus === "FundReceivedFromUser" &&
                                  "Payment Confirmed"}
                                {data?.orderStatus === "FundedProvider" &&
                                  "Awaiting Payment Confirmation"}
                              </h6>
                              {(data?.orderStatus === "FundReceivedFromUser" ||
                                data?.orderStatus === "FundedProvider") && (
                                <>
                                  {" "}
                                  <p className="mb-2">
                                    {data?.payableAmount}
                                    <span>&nbsp;{data?.payInCurrencyCode}</span>
                                  </p>
                                </>
                              )}
                              {(data?.orderStatus === "Completed" ||
                                data?.orderStatus === "Cancelled") && (
                                <p className="mb-2">
                                  {data?.receivableAmount}
                                  <span>
                                    &nbsp;{data?.receiveInCurrencyCode}
                                  </span>
                                </p>
                              )}
                            </>
                          )}

                          <div className="timing  d-flex justify-content-center mb-3 ">
                            <p className="title " style={{ width: "280px" }}>
                              {data?.orderStatus === "FundedProvider" &&
                                data?.receiveInCurrencyNetwork !== "LOCAL" && (
                                  <>
                                    {" "}
                                    We will confirm the receipts of fund, and
                                    send crypto to your wallet within the next
                                    30 minutes.{" "}
                                  </>
                                )}
                              {data?.orderStatus === "FundReceivedFromUser" &&
                                data?.receiveInCurrencyNetwork !== "LOCAL" && (
                                  <>
                                    {" "}
                                    We have received your receipts of fund, and
                                    we will send crypto to your wallet within
                                    the next 30 minutes.{" "}
                                  </>
                                )}

                              {data?.orderStatus === "FundedProvider" &&
                                data?.receiveInCurrencyNetwork === "LOCAL" && (
                                  <>
                                    We will confirm your deposit, and send your
                                    fiat to your account within the next 30
                                    minutes
                                  </>
                                )}
                              {data?.orderStatus === "FundReceivedFromUser" &&
                                data?.receiveInCurrencyNetwork === "LOCAL" && (
                                  <>
                                    {" "}
                                    We have received your deposit, and we will
                                    send fiat to your account within the next 30
                                    minutes
                                  </>
                                )}
                            </p>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12">
                            <span className="trans-head">
                              Transaction Details
                            </span>
                            <div className="trans-details d-flex mt-2 justify-content-between align-items-center">
                              <h6>Payment Method</h6>
                              <p className="mb-1">
                                {
                                  data?.providerOrderPaymentMethod
                                    ?.paymentMethods?.paymentMethod
                                }
                              </p>
                            </div>
                            <div className="trans-details d-flex justify-content-between align-items-center">
                              <h6>Amount Paid</h6>
                              <p className="mb-1">
                                {formatCurrency(data?.payableAmount)}{" "}
                                {data?.payInCurrencyCode}
                                {data?.payInCurrencyNetwork !== "LOCAL" && (
                                  <>({data?.payInCurrencyNetwork})</>
                                )}
                              </p>
                            </div>
                            {data?.receivableAmount !== 0 && (
                              <>
                                <div className="trans-details d-flex justify-content-between align-items-center">
                                  <h6>Amount to Receive</h6>
                                  <p className="mb-1">
                                    {formatCurrency(data?.receivableAmount)}{" "}
                                    {data?.receiveInCurrencyCode}{" "}
                                    {data?.receiveInCurrencyNetwork !==
                                      "LOCAL" && (
                                      <>({data?.receiveInCurrencyNetwork})</>
                                    )}
                                  </p>
                                </div>
                              </>
                            )}

                            <div className=" trans-details d-flex justify-content-between align-items-center">
                              <h6>Reference</h6>
                              <p className="subhead mb-0">
                                {data?.orderReference}
                              </p>
                            </div>

                            <div className=" trans-details d-flex justify-content-between align-items-center">
                              <h6>Date</h6>
                              <p className="mb-1">
                                {new Date(
                                  data?.initiatedAtTimestamp
                                ).toLocaleDateString()}
                                &nbsp;
                                {new Intl.DateTimeFormat("default", {
                                  hour12: true,
                                  hour: "numeric",
                                  minute: "numeric",
                                }).format(data?.initiatedAtTimestamp)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                          <a
                            href="/order-requests"
                            variant="primary"
                            className="btn btn-primary py-2 btn-style"
                            size="md"
                            // onClick={() => {
                            //   resetForm();
                            //   setActivePage("initiateOrder");
                            // }}
                          >
                            Close
                          </a>
                        </div>

                        <div className="d-flex justify-content-end mt-4 align-items-center">
                          <img
                            src={mobilechat}
                            alt="chat"
                            className="cursor-pointer d-appeal"
                            onClick={() => {
                              setDisputeStatus(data?.disputeStatus);
                              setOrderReference(data?.orderReference);
                              setDisputeReference(data?.disputeReference);
                              setModalType("AppealModal");
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-5 hide-appeal">
                    <Appeal
                      disputeStatus={data?.disputeStatus}
                      orderReference={data?.orderReference}
                      disputeReference={data?.disputeReference}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={false} />
    </div>
  );
};

export default OrderSuccess;
