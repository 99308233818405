import axiosInstance from "../utils/request";

export const readBankList = async () => {
  const { data } = await axiosInstance.get("api/Bank?status=ACTIVE");
  return data;
};

export const readSupportedCurrencies = async () => {
  const { data } = await axiosInstance.get("api/Order/SupportedCurrencies");
  return data;
};
export const readLiveTransactions = async () => {
  const { data } = await axiosInstance.get("api/Order/LiveTransactions");
  return data;
};

export const readOrderRate = async (payload) => {
  const { data } = await axiosInstance.get(
    `api/Order/Rate?payInCurrencyCode=${payload?.payInCurrencyCode}&payInCurrencyNetwork=${payload?.payInCurrencyNetwork}&receiveInCurrencyCode=${payload?.receiveInCurrencyCode}&receiveInCurrencyNetwork=${payload?.receiveInCurrencyNetwork}&orderAmount=${payload?.orderAmount}`
  );
  return data;
};

export const readOrderRateBounds = async (payload) => {
  const { data } = await axiosInstance.get(
    `api/Order/RateBounds?payInCurrencyCode=${payload?.payInCurrencyCode}&payInCurrencyNetwork=${payload?.payInCurrencyNetwork}&receiveInCurrencyCode=${payload?.receiveInCurrencyCode}&receiveInCurrencyNetwork=${payload?.receiveInCurrencyNetwork}&orderAmount=${payload?.orderAmount}`
  );
  return data;
};

export const readOrderRequests = async (payload) => {
  const { data } = await axiosInstance.post(
    `api/Order/History?filter=All&getHistoryAcrossApps=${
      payload?.getHistoryAcrossApps
    }&page=${payload?.page}&pageSize=${10}`
  );
  return data;
};

export const readOrderRequest = async (payload) => {
  const { data } = await axiosInstance.get(
    `api/Order/OrderReference?orderReference=${payload?.orderReference}`
  );
  return data;
};

export const readPendingOrderRequest = async () => {
  const { data } = await axiosInstance.get(`api/Order/PendingOrder`);
  return data;
};

export const readPaymentMethods = async (payload) => {
  const { data } = await axiosInstance.get(
    `api/Order/PaymentMethods?currency=${payload?.currencyType}`
  );
  return data;
};
