import React from "react";
import BuyOrderForm from "./BuyOrderForm";
import Footer from "./Footer";
import SellOrderForm from "./SellOrderForm";
import SwapOrderForm from "./SwapOrderForm";

const InitiateOrder = ({
  setActivePage,
  setModalType,
  setFiatValue,
  setCryptoValue,
  fiatValue,
  cryptoValue,
  setIsActive,
  isActive,
  resetForm,
  setPayInValue,
  payInValue,
  setReceiveInValue,
  receiveInValue,
}) => {
  return (
    <div className="margin-top-sm">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              <h2 className="heading text-center">
                Safe & Fast P2P Crypto Trading.
              </h2>
              <p className="get-started text-center">Get Started</p>

              <div className="order-card p-4">
                <div className="d-flex align-items-center">
                  
                  <label className="radio-container me-4">
                    Buy{" "}
                    <input
                      type="radio"
                      checked={isActive === "buy" && "checked"}
                      name="radio"
                      onChange={() => {
                        resetForm();
                        setIsActive("buy");
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="radio-container me-4">
                    <input
                      type="radio"
                      name="radio"
                      checked={isActive === "sell" && "checked"}
                      onChange={() => {
                        resetForm();
                        setIsActive("sell");
                      }}
                    />
                    Sell
                    <span className="checkmark"></span>
                  </label>

                  <label className="radio-container">
                    <input
                      type="radio"
                      name="radio"
                      checked={isActive === "swap" && "checked"}
                      onChange={() => {
                        resetForm();
                        setIsActive("swap");
                      }}
                    />
                    Swap
                    <span className="checkmark"></span>
                  </label>
                </div>

                {isActive === "sell" && (
                  <SellOrderForm
                    setActivePage={setActivePage}
                    setModalType={setModalType}
                    setFiatValue={setFiatValue}
                    setCryptoValue={setCryptoValue}
                    fiatValue={fiatValue}
                    cryptoValue={cryptoValue}
                  />
                )}

                {isActive === "buy" && (
                  <BuyOrderForm
                    setActivePage={setActivePage}
                    setModalType={setModalType}
                    setFiatValue={setFiatValue}
                    setCryptoValue={setCryptoValue}
                    fiatValue={fiatValue}
                    cryptoValue={cryptoValue}
                  />
                )}

                {isActive === "swap" && (
                  <SwapOrderForm
                    setActivePage={setActivePage}
                    setModalType={setModalType}
                    setPayInValue={setPayInValue}
                    payInValue={payInValue}
                    setReceiveInValue={setReceiveInValue}
                    receiveInValue={receiveInValue}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={true} />
    </div>
  );
};

export default InitiateOrder;
