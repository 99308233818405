import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import logo from "../../images/logo2.png";
import ReactFlagsSelect from "react-flags-select";
import { convertIso2Code } from "convert-country-codes";

const SignupFormModal = ({
  modal,
  setModalType,
  emailOrPhone,
  verificationReference,
}) => {
  // Start Context
  const { registerCustomer } = useContext(AuthContext);
  // End Context

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  // const [otherName, setOtherName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [countryIsoCode, setCountryIsoCode] = useState("NGA");
  const [selectedCountryIsoCode, setSelectedCountryIsoCode] = useState("NG");

  // const [referal, setReferal] = useState();
  const [password, setPassword] = useState();
  const [cfmPassword, setCfmPassword] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState();

  let validatePassword = password?.match(
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/
  );
  const submitHandler = async () => {
    if (
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !countryIsoCode ||
      !password
    ) {
      toast.error("Input fields cannot be blank");
    } else if (!validatePassword) {
      toast.error(
        "Password must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      );
    } else if (password !== cfmPassword) {
      toast.error("Password does not match");
    } else {
      setIsButtonLoading(true);

      try {
        let payload = {
          EmailAddress: emailOrPhone,
          verificationReference,
          firstName,
          otherName: "",
          lastName,
          phoneNumber,
          countryIsoCode,
          password,
          // referal,
        };
        let response = await registerCustomer(payload);
        if (response?.data) {
          setModalType("SignupSuccess");
        } else {
          toast.error(response?.Message || "Oops, Something went wrong");
        }
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
      setIsButtonLoading(false);
    }
  };

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-4 px-4 justify-content-center ">
          <div>
            <div className="text-center">
              <img src={logo} alt="logo" height={40} />
              <h3 className="py-3">Sign Up</h3>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12 ">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Firstname "
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>{" "}
              <div className="col-lg-12 ">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Lastname "
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>{" "}
              {/* <div className="col-lg-12 ">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Othername "
                  onChange={(e) => setOtherName(e.target.value)}
                />
              </div>{" "} */}
              <div className="col-lg-12 ">
                <input
                  type="number"
                  className="form-control mb-3 no-arrow"
                  placeholder="Phone No "
                  autoComplete="off"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="col-lg-12 ">
                <ReactFlagsSelect
                  selected={selectedCountryIsoCode}
                  searchable
                  onSelect={(code) => {
                    setSelectedCountryIsoCode(code);
                    const { iso3 } = convertIso2Code(code);
                    setCountryIsoCode(iso3);
                  }}
                  className="menu-flags h-46 mb-3"
                  placeholder="Select Country"
                />
              </div>{" "}
              {/* <div className="col-lg-12 ">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Referal (Optional) "
                  onChange={(e) => setReferal(e.target.value)}
                />
              </div>{" "} */}
              <div className="col-lg-12 ">
                <input
                  type="password"
                  className="form-control mb-3"
                  placeholder="Password "
                  onChange={(e) => setPassword(e.target.value)}
                />
                {!validatePassword && (
                  <div
                    class="d-flex align-items-center alert py-1 alert-danger mb-3"
                    role="alert"
                  >
                    <i
                      class="fa fa-2x fa-exclamation-circle text-red me-2"
                      aria-hidden="true"
                    ></i>{" "}
                    <small className="font-size-12">
                      Password must Contain 6 Characters, One Uppercase, One
                      Lowercase, One Number and One Special Case Character
                    </small>
                  </div>
                )}
              </div>
              <div className="col-lg-12 mb-3 ">
                <input
                  type="password"
                  className="form-control "
                  placeholder="Confirm Password "
                  onChange={(e) => setCfmPassword(e.target.value)}
                />
                {validatePassword && password !== cfmPassword && (
                  <small className="text-danger">Password does not match</small>
                )}
              </div>
            </div>

            <div className="d-grid gap-2 mt-4 mb-3">
              {isButtonLoading ? (
                <Button
                  disabled
                  variant="primary "
                  className="py-2 btn-style"
                  size="md"
                >
                  <i
                    className="fa fa-spinner fa-pulse mx-2 "
                    aria-hidden="true"
                  ></i>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="py-2 btn-style"
                  size="md"
                  onClick={() => submitHandler()}
                >
                  Submit
                </Button>
              )}
            </div>

            <div className="text-center">
              <a
                className="btn  py-2 close"
                href="/#"
                onClick={() => setModalType()}
              >
                Close
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignupFormModal;
