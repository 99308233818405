import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import arrow from "../images/arrow.svg";
import Footer from "./Footer";
import { banks } from "../utils/bankList";
import { GlobalContext } from "../contexts/GlobalContext";
const BuyPaymentDetailsForm = ({
  setActivePage,
  setFiatPaymentType,
  setAcctName,
  setAcctNumber,
  setBankName,
  fiatPaymentType,
  setFiatPaymentMethod,
  bankName,
  acctName,
  acctNumber,
  fiatPayment,
  setFiatPayment,
  setMobileMoneyCurrency,
  setPhoneNo,
  phoneNo,
  setEmailAddress,
  setFintechCurrency,
  emailAddress,
}) => {
  // Start Context
  const {
    paymentMethods,
    getPaymentMethods,
    getSupportedCurrencies,
    fiatCurrencies,
    fiatCurrency,
    getBankList,
    bankList,
  } = useContext(GlobalContext);
  // End Context

  const [isOptionLoading, setIsOptionLoading] = useState(true);

  const getPaymentOptions = () => {
    setIsOptionLoading(true);
    getPaymentMethods("FIAT");
    paymentMethods && setIsOptionLoading(false);
  };

  useEffect(() => {
    getBankList();
    getPaymentOptions();
    getSupportedCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="margin-top-sm receiving-details">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              <p className="title ">Payment details</p>

              <div className="order-card px-4 pt-4 pb-5">
                <img
                  src={arrow}
                  alt="previous arrow"
                  className="cursor-pointer"
                  onClick={() => setActivePage("initiateOrder")}
                />
                <div className="mt-3 ">
                  <p className="">Select Method for sending funds</p>
                  <div className="form-group d-flex align-items-center">
                    <select
                      className=" px-2 py-3 form-control-2 select-lg"
                      onChange={(e) => {
                        setFiatPayment(e.target.value);
                        setFiatPaymentType(
                          e.target.value !== ""
                            ? JSON.parse(e.target.value)?.paymentType
                            : ""
                        );
                        setFiatPaymentMethod(
                          e.target.value !== ""
                            ? JSON.parse(e.target.value)?.method
                            : ""
                        );
                      }}
                      defaultValue={fiatPayment}
                    >
                      <option value={""}> Select Method </option>
                      {paymentMethods?.map((item) => (
                        <option
                          key={item?.methodReference}
                          value={JSON.stringify(item)}
                        >
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {isOptionLoading && (
                      <div>
                        <i
                          className="fa fa-circle-o-notch text-secondary fa-pulse mx-2 "
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>

                  {/* Bank Transfer Form */}
                  {fiatPaymentType === "Bank" && (
                    <>
                      <div className="form-group mt-4">
                        <label className="form-label mb-0" htmlFor="bank">
                          Select Bank
                        </label>
                        <select
                          className="form-control"
                          defaultValue={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                        >
                          <option value="">Select </option>
                          {/* TODO:  bankList.map - when the api is available */}
                          {banks?.map(({ name, id }) => (
                            <option value={name} key={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="acctNo">
                          Account Number
                        </label>{" "}
                        <input
                          type="text"
                          className="form-control"
                          value={acctNumber}
                          onChange={(e) => setAcctNumber(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="acctName">
                          Account Name
                        </label>{" "}
                        <input
                          type="text"
                          className="form-control"
                          value={acctName}
                          onChange={(e) => setAcctName(e.target.value)}
                        />
                      </div>
                      <div className="d-grid gap-2 mt-4">
                        <Button
                          variant="primary"
                          className="py-2 btn-style"
                          size="md"
                          disabled={!acctName || !acctNumber || bankName === ""}
                          onClick={() =>
                            setActivePage("buyReceivingDetailsForm")
                          }
                        >
                          Continue
                        </Button>
                      </div>
                    </>
                  )}
                  {/* End Bank Transfer Form */}

                  {/* MobileMoney Form */}
                  {fiatPaymentType === "MobileMoney" && (
                    <>
                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="acctName">
                          Account Name
                        </label>{" "}
                        <input
                          type="text"
                          className="form-control"
                          value={acctName}
                          onChange={(e) => setAcctName(e.target.value)}
                        />
                      </div>{" "}
                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="phoneNo">
                          Phone No.
                        </label>{" "}
                        <input
                          value={phoneNo}
                          type="number"
                          className="form-control no-arrow"
                          onChange={(e) => setPhoneNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="currency">
                          Currency
                        </label>
                        <select
                          className="form-control custom-select"
                          onChange={() => setMobileMoneyCurrency(fiatCurrency)}
                          defaultValue={fiatCurrency}
                          disabled
                        >
                          <option value="">Select Currency</option>
                          {fiatCurrencies?.map((item) => {
                            return (
                              <option value={item?.currencySymbolText}>
                                {item?.currencySymbolText}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="d-grid gap-2 mt-4">
                        <Button
                          variant="primary"
                          className="py-2 btn-style"
                          size="md"
                          disabled={!acctName || !phoneNo}
                          onClick={() =>
                            setActivePage("buyReceivingDetailsForm")
                          }
                        >
                          Continue
                        </Button>
                      </div>
                    </>
                  )}
                  {/* End MobileMoney Form */}

                  {/* Fintech Form */}
                  {fiatPaymentType === "Fintech" && (
                    <>
                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="acctName">
                          Account Name
                        </label>{" "}
                        <input
                          type="text"
                          className="form-control"
                          value={acctName}
                          onChange={(e) => setAcctName(e.target.value)}
                        />
                      </div>{" "}
                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="email">
                          Email Address
                        </label>{" "}
                        <input
                          type="text"
                          className="form-control"
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="form-label mb-0" htmlFor="currency">
                          Currency
                        </label>
                        <select
                          className="form-control custom-select"
                          onChange={() => setFintechCurrency(fiatCurrency)}
                          defaultValue={fiatCurrency}
                          disabled
                        >
                          <option value="">Select Currency</option>
                          {fiatCurrencies?.map((item) => {
                            return (
                              <option value={item?.currencySymbolText}>
                                {item?.currencySymbolText}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="d-grid gap-2 mt-4">
                        <Button
                          variant="primary"
                          className="py-2 btn-style"
                          size="md"
                          disabled={!acctName || !emailAddress}
                          onClick={() =>
                            setActivePage("buyReceivingDetailsForm")
                          }
                        >
                          Continue
                        </Button>
                      </div>
                    </>
                  )}
                  {/* End Fintech Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={false} />
    </div>
  );
};

export default BuyPaymentDetailsForm;
