export const getItemFromSessionStorage = (data) => {
  if (sessionStorage.getItem(data) !== "undefined") {
    return JSON.parse(sessionStorage.getItem(data));
  }
};

export const formatCurrency = (amount) => {
  return Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatWalletAddress = (walletAddress) => {
  let hideNum = [];
  for (let i = 0; i < walletAddress.length; i++) {
    if (i > 12 && i < walletAddress.length - 6) {
      hideNum.push("*");
    } else {
      hideNum.push(walletAddress[i]);
    }
  }
  return hideNum.join("");
};

export const formatAcctNumber = (accountNumber) => {
  let hideNum = [];
  for (let i = 0; i < accountNumber.length; i++) {
    if (i < accountNumber.length - 4) {
      hideNum.push("X");
    } else {
      hideNum.push(accountNumber[i]);
    }
  }
  return hideNum.join("");
};

export const formatTime = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " year ago"
      : Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " month ago"
      : Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " day ago"
      : Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " hour ago"
      : Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " min ago"
      : Math.floor(interval) + " mins ago";
  }
  return Math.floor(seconds) + " secs ago";
};
