import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Index from './pages/index';
import OrderHistory from './pages/orderhistory';
import AuthProvider from './contexts/AuthContext';
import GlobalProvider from './contexts/GlobalContext';
import ModalComponent from './components/Modals';
import Header from './components/Header';
import BuyCryptoPendingDetails from './components/BuyCryptoPendingDetails';
import SellCryptoPendingDetails from './components/SellCryptoPendingDetails';
import OrderSuccessPage from './components/OrderSuccessPage';
import { ProtectedRoute } from './utils/protectedRoute';

function App() {
  const [modal, setModal] = useState(true);

  const [modalType, setModalType] = useState('');
  const [disputeStatus, setDisputeStatus] = useState('');
  const [disputeReference, setDisputeReference] = useState('');
  const [orderReference, setOrderReference] = useState('');
  const [emailOrPhone, setEmailOrPhone] = useState();
  const [verificationReference, setVerificationReference] = useState();

  useEffect(() => {
    // enforce an  https on the client side
    if (window.location.href.substr(0, 5) !== 'https') {
      window.location.href = window.location.href.replace('http', 'https');
    }
  }, []);

  return (
    <>
      {' '}
      <AuthProvider>
        <GlobalProvider>
          <BrowserRouter>
            {' '}
            <Header setModalType={setModalType} />
            <Routes>
              <Route
                path="/"
                element={
                  <Index
                    setModalType={setModalType}
                    setEmailOrPhone={setEmailOrPhone}
                    setVerificationReference={setVerificationReference}
                    modalType={modalType}
                    setDisputeStatus={setDisputeStatus}
                    setOrderReference={setOrderReference}
                    setDisputeReference={setDisputeReference}
                  />
                }
              />
              <Route
                path="/order-requests"
                element={
                  <ProtectedRoute>
                    <OrderHistory />{' '}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/view-order-request/:id"
                element={
                  <ProtectedRoute>
                    <OrderSuccessPage
                      setDisputeStatus={setDisputeStatus}
                      setModalType={setModalType}
                      setOrderReference={setOrderReference}
                      setDisputeReference={setDisputeReference}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/view-pending-request"
                element={
                  <ProtectedRoute>
                    {' '}
                    <BuyCryptoPendingDetails
                      setDisputeStatus={setDisputeStatus}
                      setModalType={setModalType}
                      setOrderReference={setOrderReference}
                      setDisputeReference={setDisputeReference}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pending-request"
                element={
                  <ProtectedRoute>
                    <SellCryptoPendingDetails
                      setModalType={setModalType}
                      modalType={modalType}
                      setDisputeStatus={setDisputeStatus}
                      setOrderReference={setOrderReference}
                      setDisputeReference={setDisputeReference}
                    />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <ModalComponent
              modal={modal}
              setModal={setModal}
              modalType={modalType}
              setModalType={setModalType}
              emailOrPhone={emailOrPhone}
              setEmailOrPhone={setEmailOrPhone}
              setVerificationReference={setVerificationReference}
              verificationReference={verificationReference}
              disputeStatus={disputeStatus}
              setDisputeStatus={setDisputeStatus}
              setOrderReference={setOrderReference}
              setDisputeReference={setDisputeReference}
              orderReference={orderReference}
              disputeReference={disputeReference}
            />
          </BrowserRouter>
        </GlobalProvider>
      </AuthProvider>
    </>
  );
}

export default App;
