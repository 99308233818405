import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import arrow from '../../../images/arrow.svg';
import Footer from '../../Footer';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { formatCurrency, formatWalletAddress } from '../../../utils/fx';

const CryptoConfirmPurchase = ({
  setActivePage,
  payWalletAddress,
  payInValue,
  receiveInValue,
  setOrderWalletAddress,
  setOrderReference,
  receiveWalletAddress,
}) => {
  // Start Context
  const {
    bestRate,
    initiateSwapOrder,
    payInCurrency,
    payInCurrencyNetwork,
    receiveInCurrency,
    receiveInNetwork,
    receiveInCurrencyImg,
  } = useContext(GlobalContext);
  // End Context

  const [isButtonLoading, setIsButtonLoading] = useState();

  const submitHandler = async () => {
    setIsButtonLoading(true);
    try {
      let payload = {
        payInCurrencyCode: payInCurrency,
        payInCurrencyNetwork: payInCurrencyNetwork,
        receiveInCurrencyCode: receiveInCurrency,
        receiveInCurrencyNetwork: receiveInNetwork,
        orderAmount: parseFloat(payInValue),
        consumerDepositMethod: {
          paymentMethod: 'Crypto',
          paymentType: 'Crypto',
          paymentData: {
            walletAddress: payWalletAddress,
            network: payInCurrencyNetwork,
          },
        },
        consumerReceiptMethod: {
          paymentMethod: 'Crypto',
          paymentType: 'Crypto',
          paymentData: {
            walletAddress: receiveWalletAddress,
            network: receiveInNetwork,
          },
        },
      };
      let response = await initiateSwapOrder(payload);
      if (response?.Status === 2) {
        toast.error(response?.Message || 'Oops, Something went wrong');
      } else if (response?.data) {
        setOrderReference(response?.data?.orderReference);
        setOrderWalletAddress(
          response?.data?.providerPaymentMethods?.orderPaymentMethod
            ?.walletAddress
        );

        setActivePage('buyCrypto_');
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || 'Oops, Something went wrong');
    }

    setIsButtonLoading(false);
  };

  return (
    <div className="margin-top-sm confirm-purchase">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              <p className="title ">Confirm Purchase</p>

              <div className="order-card p-4" style={{ width: '350px' }}>
                <img
                  src={arrow}
                  alt="previous arrow"
                  className="cursor-pointer"
                  onClick={() =>
                    setActivePage('receiveCryptoPaymentDetailsForm')
                  }
                />
                <div className="mt-3  text-center">
                  <div className="payment mb-4">
                    <p className="mb-1">When you pay</p>
                    <h6>
                      {formatCurrency(payInValue)} <span>{payInCurrency}</span>
                    </h6>
                  </div>
                  <div className="payment mb-4">
                    <p className="mb-1">You will receive</p>
                    <h6>
                      {formatCurrency(receiveInValue)}{' '}
                      <span>{receiveInCurrency}</span>{' '}
                    </h6>
                  </div>
                  {bestRate && (
                    <div className="rate mb-4">
                      <span>Best Rate</span>
                      <p style={{ fontSize: '12px' }}>
                        1 {payInCurrency} ~ {bestRate} {receiveInCurrency}
                      </p>
                    </div>
                  )}
                  <div className="mb-3 mt-5">
                    <div className="d-flex justify-content-between mb-0">
                      <label className="form-label mb-1" htmlFor="pay">
                        Recipient Address
                      </label>
                      <small
                        className="form-label mb-1 cursor-pointer"
                        onClick={() =>
                          setActivePage('receiveCryptoPaymentDetailsForm')
                        }
                        style={{ color: '#4C9AFF' }}
                      >
                        Change
                      </small>
                    </div>

                    <div className="recipientAdd p-3">
                      {' '}
                      <img
                        src={receiveInCurrencyImg}
                        alt="currency"
                        height={36}
                        className="me-3"
                      />
                      <div style={{ textAlign: 'left' }}>
                        <h6 className="mb-0">
                          {receiveInCurrency} {receiveInNetwork}
                        </h6>
                        <p className="mb-0" style={{ fontSize: 'xx-small' }}>
                          {formatWalletAddress(receiveWalletAddress)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="d-grid gap-2">
                    {isButtonLoading ? (
                      <Button
                        disabled
                        variant="primary "
                        className="py-2 btn-style"
                        size="md"
                      >
                        <i
                          className="fa fa-spinner fa-pulse mx-2 "
                          aria-hidden="true"
                        ></i>
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        className="py-2 btn-style"
                        size="md"
                        onClick={() => submitHandler()}
                      >
                        Confirm Purchase
                      </Button>
                    )}
                  </div>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={false} />
    </div>
  );
};

export default CryptoConfirmPurchase;
