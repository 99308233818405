import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import logo from "../../images/verify.svg";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";

const VerifySignupModal = ({
  modal,
  setModalType,
  emailOrPhone,
  setVerificationReference,
}) => {
  // Start Context
  const { validateOTPForEmail, generateOTPForEmail } = useContext(AuthContext);
  // End Context

  const [otp, setOtp] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState();
  const [isLoading, setIsLoading] = useState();
  const handleChange = (otp) => setOtp(otp);

  const submitHandler = async () => {
    if (!otp) {
      toast.error(
        "Please enter the one-time password sent to your email address"
      );
    } else {
      setIsButtonLoading(true);

      try {
        let payload = {
          emailOrPhone,
          otp,
        };
        let response = await validateOTPForEmail(payload);

        if (response?.data?.verificationReference) {
          setModalType("SignupForm");
          setVerificationReference(response?.data?.verificationReference);
        } else {
          toast.error(response?.Message || "Oops, Something went wrong");
        }
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
      setIsButtonLoading(false);
    }
  };

  const resendOtpHandler = async () => {
    setIsLoading(true);

    let payload = {
      emailOrPhone: emailOrPhone,
    };

    try {
      let response = await generateOTPForEmail(payload);
      if (response?.message) {
        toast.success(response?.message);
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || "Oops, Something went wrong");
    }

    setIsLoading(false);
  };
  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-4 px-5 justify-content-center text-center">
          <div>
            <img src={logo} alt="logo" height={40} />
            <h3 className="py-3">Verify Signup</h3>
            <p className="mb-1">Enter the one-time password we sent to: </p>
            <span className="user-email ">{emailOrPhone}</span>
            <div className="form-group d-flex justify-content-center mb-4 mt-5">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                className="inputStyle"
              />
            </div>
            <div className="terms my-5 ">
              <a
                href="/#"
                onClick={() => setModalType("Signup")}
                style={{ color: "#797979" }}
              >
                Change email
              </a>
              <span style={{ color: "#4C9AFF" }}>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </span>
              {isLoading ? (
                <i
                  className="fa fa-spinner fa-pulse mx-4 "
                  aria-hidden="true"
                ></i>
              ) : (
                <a
                  href="/#"
                  onClick={() => resendOtpHandler()}
                  style={{ color: "#797979" }}
                >
                  Resend Code
                </a>
              )}
            </div>
            <div className="d-grid gap-2 mt-4 mb-3">
              {" "}
              {isButtonLoading ? (
                <Button
                  disabled
                  variant="primary "
                  className="py-2 btn-style"
                  size="md"
                >
                  <i
                    className="fa fa-spinner fa-pulse mx-2 "
                    aria-hidden="true"
                  ></i>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="py-2 btn-style"
                  size="md"
                  onClick={() => submitHandler()}
                >
                  Done
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifySignupModal;
