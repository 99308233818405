import React from "react";
import { Modal } from "react-bootstrap";
import Appeal from "../Appeal";

const AppealModal = ({
  modal,
  setModalType,
  disputeStatus,
  orderReference,
  disputeReference,
}) => {
  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="py-4 px-3">
          <Appeal
            type="mobile"
            disputeStatus={disputeStatus}
            orderReference={orderReference}
            disputeReference={disputeReference}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AppealModal;
