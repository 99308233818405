import React, { useContext, useEffect, useState } from "react";
import { FormControl, InputGroup, Dropdown } from "react-bootstrap";
import { GlobalContext } from "../../contexts/GlobalContext";

const ReceiveIn = ({
  receiveInValue,
  handleOrderRate,
  setMinAmountErrorMessage_,
  setMaxAmountErrorMessage_,
  maxAmount_,
  // setReceiveInCurrency,
  // setReceiveInCurrencyImg,
  setCurrencyTypeErrorMessage,
  setCurrencyType,
  setReceiveInCurrencyType,
  setReceiveInValue,
  // receiveInCurrency,
  // receiveInCurrencyImg,
  supportedCurrencies,
  setReceiveInNetwork,
  payInCurrencyType,
  activeCurrencyType,
  setCryptoNetworkReceiveInValue,
  cryptoNetworkReceiveInImg,
  setCryptoNetworkReceiveInImg,
  cryptoNetworkReceiveInValue,
}) => {
  const {
    receiveInCurrency,
    receiveInCurrencyImg,
    setReceiveInCurrency,
    setReceiveInCurrencyImg,
  } = useContext(GlobalContext);

  const [networkCryptoCurrencies, setNetworkCryptoCurrencies] = useState();

  useEffect(() => {
    let networkCryptoCurrencies_ = supportedCurrencies.filter((item) =>
      activeCurrencyType === "crypto"
        ? item.network === cryptoNetworkReceiveInValue
        : item.network === "LOCAL"
    );
    setNetworkCryptoCurrencies(networkCryptoCurrencies_);
    setReceiveInCurrency(
      networkCryptoCurrencies_[1]
        ? networkCryptoCurrencies_[1]?.currencySymbol
        : networkCryptoCurrencies_[0]?.currencySymbol
    );
    setReceiveInCurrencyImg(
      networkCryptoCurrencies_[1]
        ? networkCryptoCurrencies_[1]?.symbolUrl
        : networkCryptoCurrencies_[0]?.symbolUrl
    );
    setReceiveInNetwork(cryptoNetworkReceiveInValue);
  }, [cryptoNetworkReceiveInValue, supportedCurrencies]);

  return (
    <>
      {" "}
      <InputGroup className="mb-2">
        <FormControl
          placeholder="Enter amount"
          className={`${
            activeCurrencyType === "crypto" ? "input-width-2" : "input-width"
          }`}
          value={receiveInValue}
          onChange={(e) => {
            handleOrderRate("ReceiveIn", e.target.value);
            if (e.target.value < 1) {
              setMinAmountErrorMessage_(true);
            } else if (e.target.value > maxAmount_) {
              setMaxAmountErrorMessage_(true);
            } else {
              setMinAmountErrorMessage_(false);
              setMaxAmountErrorMessage_(false);
            }
          }}
        />

        <Dropdown>
          <Dropdown.Toggle
            variant=""
            as="div"
            className={`form-control style-1 default-select text-dark-black d-flex align-items-center   p-2 ${
              activeCurrencyType === "crypto" && "pe-3"
            }`}
            style={{ fontSize: "12px" }}
          >
            {activeCurrencyType === "crypto" &&
            networkCryptoCurrencies?.length < 1 ? (
              <> &nbsp;&nbsp; &nbsp;&nbsp; ------</>
            ) : (
              <>
                {receiveInCurrencyImg && (
                  <img
                    src={receiveInCurrencyImg}
                    alt="currencyImage"
                    height={13}
                  />
                )}
                &nbsp;&nbsp;{receiveInCurrency}
              </>
            )}
          </Dropdown.Toggle>{" "}
          <Dropdown.Menu>
            {networkCryptoCurrencies?.map((item, i) => (
              <Dropdown.Item
                style={{ fontSize: "13px", padding: " 0.7rem 1.2rem" }}
                onClick={() => {
                  setCurrencyType(item.currencyType);
                  setReceiveInCurrencyType(item.currencyType);
                  setReceiveInValue("");
                  setReceiveInCurrency(item?.currencySymbol);
                  setReceiveInCurrencyImg(item?.symbolUrl);
                  setReceiveInNetwork(item?.network);

                  //   if (payInCurrencyType !== item.currencyType) {
                  //     setCurrencyTypeErrorMessage(true);
                  //   } else {
                  //     setCurrencyTypeErrorMessage(false);
                  //   }
                }}
                key={i}
              >
                {item?.symbolUrl && (
                  <>
                    <img
                      src={item?.symbolUrl}
                      alt="currencyImage"
                      height={13}
                    />
                    &nbsp;&nbsp;
                  </>
                )}
                <strong>{item?.currencySymbol}</strong>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {item?.name}&nbsp;&nbsp;({item?.network})
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    </>
  );
};

export default ReceiveIn;
