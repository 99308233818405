/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { formatTime } from "../utils/fx";

const Footer = ({ showTrans }) => {
  // Start Context
  const { liveTransactions, getLiveTransactions } = useContext(GlobalContext);
  // End Context

  useEffect(() => {
    getLiveTransactions();
    const intervalCall = setInterval(() => {
      getLiveTransactions();
    }, 20000); //20 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-4">
      <div className="text-center footer px-3">
        <span style={{ cursor: "pointer" }}>Terms of Service</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="mailto:Hello@Xendbridge.com"
          style={{ textDecoration: "none" }}
        >
          {" "}
          Help & Support
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span>© {new Date().getFullYear()} XendBridge All right reserved</span>
      </div>

      {showTrans && liveTransactions?.length > 7 && (
        <div className="live-trans mt-3 ">
          <label className="live-trans-container d-flex align-items-center px-4">
            <div className="circlemark me-2"></div> Live Transactions
          </label>

          <marquee>
            {" "}
            <div className="marquee d-flex">
              {liveTransactions?.map((item, i) => {
                return (
                  <div className="d-flex mt-3" key={i}>
                    <div className="parent-box">
                      <img
                        src={item?.recieveInCurrencyImageUrl}
                        className="parent-image1"
                        alt="currency"
                        height={36}
                      />
                      <img
                        src={item?.payInCurrencyImageUrl}
                        className="parent-image2"
                        alt="currency"
                        height={36}
                      />
                    </div>
                    <div className="ms-5 position-relative">
                      <div className="d-flex justify-content-between align-items-end">
                        <h6 className="mb-0">{item?.orderType}</h6>
                        <div className="trans-time">
                          {formatTime(item?.orderInitatedAt)}
                        </div>
                      </div>
                      <p className="mb-0">
                        {item?.orderAmount} {item?.payInCurrencySymbol}{" "}
                        XXX.....XXX
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </marquee>
        </div>
      )}
    </div>
  );
};

export default Footer;
