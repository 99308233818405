import React from "react";

export default function PageLoader() {
  return (
    <div className="container text-primary">
      <div className="row">
        <div className="col d-flex align-items-center justify-content-center " style={{height: '40vh'}}>
          <div>
            <i className="fa fa-spinner fa-2x fa-pulse"></i> <span style={{fontSize: '25px'}}>loading data...</span>
          </div>
        </div>
      </div>
    </div>
  );
}
