import React, { useState, useContext, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { GlobalContext } from "../contexts/GlobalContext";
import { toast } from "react-toastify";
import { getItemFromSessionStorage } from "../utils/fx";
import ReceiveInInput from "./Input/ReceiveIn";
import PayInInput from "./Input/PayIn";
import { cryptoNetworks } from "../utils/cryptoNetworks";
import CheckBox from "./Checkbox";

const isLoggedIn = getItemFromSessionStorage("accessToken");

const SwapOrderForm = ({
  setActivePage,
  setModalType,

  setPayInValue,
  payInValue,
  setReceiveInValue,
  receiveInValue,
}) => {
  // Start Context
  const {
    getOrderRate,
    getBestRate,
    bestRate,
    getSupportedCurrencies,
    startingRate,
    rateError,
    // supportedCurrencies,

    fiatCurrencies,
    cryptoCurrencies,
    payInCurrency,
    payInCurrencyImg,
    payInCurrencyNetwork,
    receiveInCurrency,
    receiveInCurrencyImg,
    receiveInNetwork,
    setPayInCurrency,
    setPayInCurrencyImg,
    setPayInNetwork,
    setReceiveInCurrency,
    setReceiveInCurrencyImg,
    setReceiveInNetwork,
    setCryptoNetworkReceiveInValue,
    cryptoNetworkReceiveInImg,
    setCryptoNetworkReceiveInImg,
    cryptoNetworkReceiveInValue,
    setCryptoNetworkPayInValue,
    cryptoNetworkPayInImg,
    setCryptoNetworkPayInImg,
    cryptoNetworkPayInValue,
    // setCryptoNetworkImg,
    // cryptoNetworkImg,
    // setCryptoNetworkValue,
    // cryptoNetworkValue,
  } = useContext(GlobalContext);
  // End Context

  const [activeCurrencyType, setActiveCurrencyType] = useState("fiat");
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);

  const [minAmountErrorMessage, setMinAmountErrorMessage] = useState(false);
  const [maxAmountErrorMessage, setMaxAmountErrorMessage] = useState(false);
  const [minAmountErrorMessage_, setMinAmountErrorMessage_] = useState(false);
  const [maxAmountErrorMessage_, setMaxAmountErrorMessage_] = useState(false);
  const [, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [, setMinAmount_] = useState();
  const [maxAmount_, setMaxAmount_] = useState();
  const [currencyType, setCurrencyType] = useState("FIAT");
  const [payInCurrencyType, setPayInCurrencyType] = useState("FIAT");
  const [receiveInCurrencyType, setReceiveInCurrencyType] = useState("FIAT");
  // const [currencyTypeErrorMessage, setCurrencyTypeErrorMessage] =
  //   useState(false);

  useEffect(() => {
    getBestRate(
      null,
      null,
      null,
      null,
      "swap",
      payInCurrency,
      payInCurrencyNetwork,
      receiveInCurrency,
      receiveInNetwork
    );
    const intervalCall = setInterval(() => {
      getBestRate(
        null,
        null,
        null,
        null,
        "swap",
        payInCurrency,
        payInCurrencyNetwork,
        receiveInCurrency,
        receiveInNetwork
      );
    }, 20000); //20 seconds
    return () => {
      clearInterval(intervalCall);
    };
  }, [
    payInCurrency,
    payInCurrencyNetwork,
    receiveInCurrency,
    receiveInNetwork,
    getBestRate,
  ]);

  useEffect(() => {
    getSupportedCurrencies();
  }, []);

  const submitHandler = async () => {
    if (currencyType === "FIAT") {
      setActivePage("payPaymentDetailsForm");
    } else {
      setActivePage("payCryptoPaymentDetailsForm");
    }
  };

  const handleOrderRate = async (type, value) => {
    // Accepts only integer and decimal
    let formattedValue = value.replace(/[^0-9.]/gi, "");

    if (type === "PayIn") {
      let payload = {
        payInCurrencyCode: payInCurrency,
        payInCurrencyNetwork: payInCurrencyNetwork,
        receiveInCurrencyCode: receiveInCurrency,
        receiveInCurrencyNetwork: receiveInNetwork,
        orderAmount: formattedValue,
      };

      setPayInValue(formattedValue);
      try {
        let response = await getOrderRate(payload);

        setMaxAmount(response?.data?.maximumAmount);
        setMinAmount(response?.data?.minimumAmount);

        if (formattedValue * response?.data?.exchangeRate < 1) {
          setMinAmountErrorMessage_(true);
        } else {
          setMinAmountErrorMessage_(false);
        }

        if (formattedValue < response?.data?.minimumAmount) {
          setMinAmountErrorMessage(true);
        } else if (formattedValue > response?.data?.maximumAmount) {
          setMaxAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
          setMaxAmountErrorMessage(false);
        }

        formattedValue !== ""
          ? setReceiveInValue(formattedValue * response?.data?.exchangeRate)
          : setReceiveInValue("");
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
    } else {
      setReceiveInValue(formattedValue);
      try {
        let response = await getBestRate(
          null,
          null,
          null,
          null,
          "swap",
          payInCurrency,
          payInCurrencyNetwork,
          receiveInCurrency,
          receiveInNetwork
        );

        setMaxAmount_(response?.data?.bestRate?.maximumAmount);
        setMinAmount_(response?.data?.bestRate?.minimumAmount);

        if (formattedValue * response?.data?.bestRate?.exchangeRate < 1) {
          setMinAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
        }

        if (formattedValue < response?.data?.bestRate?.minimumAmount) {
          setMinAmountErrorMessage_(true);
        } else if (formattedValue > response?.data?.bestRate?.maximumAmount) {
          setMaxAmountErrorMessage_(true);
        } else {
          setMinAmountErrorMessage_(false);
          setMaxAmountErrorMessage_(false);
        }

        formattedValue !== ""
          ? setPayInValue(
              formattedValue / response?.data?.bestRate?.exchangeRate
            )
          : setPayInValue("");
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
    }
  };

  useEffect(() => {
    // Extract the currency with "USDT"
    const usdtIndex = cryptoCurrencies.findIndex(
      (item) => item.currencySymbol === "USDT"
    );

    // Move the "USDT" currency to the beginning of the array
    if (usdtIndex !== -1) {
      const usdtItem = cryptoCurrencies.splice(usdtIndex, 1)[0];
      cryptoCurrencies.unshift(usdtItem);
    }

    let supportedCurrencies_ =
      activeCurrencyType === "fiat" ? fiatCurrencies : cryptoCurrencies;

    setSupportedCurrencies(supportedCurrencies_);
    setPayInCurrency(supportedCurrencies_[0]?.currencySymbol);
    setReceiveInCurrency(supportedCurrencies_[1]?.currencySymbol);
    setPayInCurrencyImg(supportedCurrencies_[0]?.symbolUrl);
    setReceiveInCurrencyImg(supportedCurrencies_[1]?.symbolUrl);
    setPayInNetwork(supportedCurrencies_[0]?.network);
    setReceiveInNetwork(supportedCurrencies_[1]?.network);
  }, [
    activeCurrencyType,
    cryptoCurrencies,
    fiatCurrencies,
    supportedCurrencies,
  ]);

  return (
    <div className="mt-3">
      <div>
        <div className="d-flex  align-items-center mb-2">
          <label className="form-label mb-0 me-4" htmlFor="pay">
            You Pay In{" "}
          </label>
          <form>
            <div className="d-flex align-items-center">
              <div>
                {" "}
                <CheckBox
                  checked={activeCurrencyType === "fiat" && "checked"}
                  key="fiat"
                  onChange={() => {
                    setActiveCurrencyType("fiat");
                    setPayInValue("");
                    setReceiveInValue("");
                  }}
                />{" "}
                <span
                  className=" font-size-15 me-4 ms-4 "
                  style={{ paddingTop: ".1rem" }}
                >
                  Fiat
                </span>
              </div>
              <div>
                {" "}
                <CheckBox
                  key="crypto"
                  checked={activeCurrencyType === "crypto" && "checked"}
                  onChange={() => {
                    setActiveCurrencyType("crypto");
                    setPayInValue("");
                    setReceiveInValue("");
                  }}
                />{" "}
                <span
                  className=" font-size-15 me-4 ms-4 "
                  style={{ paddingTop: ".1rem" }}
                >
                  Crypto
                </span>
              </div>
              {/* <label
              className="radio-container font-size-15 me-4 "
              style={{ paddingTop: ".1rem" }}
            >
              Fiat{" "}
              <input
                type="radio"
                checked={activeCurrencyType === "fiat" && "checked"}
                name="radio"
                key="fiat"
                onChange={() => {
                  setActiveCurrencyType("fiat");
                }}
              />
              <span className="checkmark"></span>
            </label>
            <label
              className="radio-container font-size-15  me-4"
              style={{ paddingTop: ".1rem" }}
            >
              <input
                type="radio"
                name="radio"
                key="crypto"
                checked={activeCurrencyType === "crypto" && "checked"}
                onChange={() => {
                  setActiveCurrencyType("crypto");
                }}
              />
              Crypto
              <span className="checkmark"></span>
            </label> */}
            </div>
          </form>
        </div>
        {activeCurrencyType === "crypto" && (
          <div className="d-flex justify-content-end">
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                as="div"
                className=" style-1 default-select text-dark-black d-flex align-items-center  p-2 pe-2"
                style={{ fontSize: "12px" }}
              >
                {cryptoNetworkPayInImg && (
                  <img
                    src={cryptoNetworkPayInImg}
                    alt="currencyImage"
                    height={13}
                  />
                )}
                &nbsp;&nbsp;{cryptoNetworkPayInValue}
              </Dropdown.Toggle>{" "}
              <Dropdown.Menu>
                {cryptoNetworks?.map((item, i) => (
                  <Dropdown.Item
                    style={{ fontSize: "12px", padding: " 0.7rem 1.2rem" }}
                    onClick={() => {
                      setCryptoNetworkPayInValue("");
                      setCryptoNetworkPayInImg(item?.symbolUrl);
                      setCryptoNetworkPayInValue(item?.name);
                    }}
                    key={i}
                  >
                    {item?.symbolUrl && (
                      <>
                        <img
                          src={item?.symbolUrl}
                          alt="currencyImage"
                          height={13}
                        />
                        &nbsp;&nbsp;
                      </>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {item?.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <PayInInput
          payInValue={payInValue}
          handleOrderRate={handleOrderRate}
          setMinAmountErrorMessage={setMinAmountErrorMessage}
          setMaxAmountErrorMessage={setMaxAmountErrorMessage}
          maxAmount={maxAmount}
          setPayInCurrency={setPayInCurrency}
          setPayInCurrencyImg={setPayInCurrencyImg}
          // setCurrencyTypeErrorMessage={setCurrencyTypeErrorMessage}
          setCurrencyType={setCurrencyType}
          setPayInCurrencyType={setPayInCurrencyType}
          setPayInValue={setPayInValue}
          payInCurrency={payInCurrency}
          payInCurrencyImg={payInCurrencyImg}
          supportedCurrencies={supportedCurrencies}
          setPayInNetwork={setPayInNetwork}
          receiveInCurrencyType={receiveInCurrencyType}
          activeCurrencyType={activeCurrencyType}
          setCryptoNetworkPayInValue={setCryptoNetworkPayInValue}
          cryptoNetworkPayInImg={cryptoNetworkPayInImg}
          setCryptoNetworkPayInImg={setCryptoNetworkPayInImg}
          cryptoNetworkPayInValue={cryptoNetworkPayInValue}
        />
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <label className="form-label mb-0" htmlFor="pay">
            You Receive
          </label>
          {activeCurrencyType === "crypto" && (
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                as="div"
                className=" style-1 default-select text-dark-black d-flex align-items-center  p-2 pe-2"
                style={{ fontSize: "12px" }}
              >
                {cryptoNetworkReceiveInImg && (
                  <img
                    src={cryptoNetworkReceiveInImg}
                    alt="currencyImage"
                    height={13}
                  />
                )}
                &nbsp;&nbsp;{cryptoNetworkReceiveInValue}
              </Dropdown.Toggle>{" "}
              <Dropdown.Menu>
                {cryptoNetworks?.map((item, i) => (
                  <Dropdown.Item
                    style={{ fontSize: "12px", padding: " 0.7rem 1.2rem" }}
                    onClick={() => {
                      setCryptoNetworkReceiveInValue("");
                      setCryptoNetworkReceiveInImg(item?.symbolUrl);
                      setCryptoNetworkReceiveInValue(item?.name);
                    }}
                    key={i}
                  >
                    {item?.symbolUrl && (
                      <>
                        <img
                          src={item?.symbolUrl}
                          alt="currencyImage"
                          height={13}
                        />
                        &nbsp;&nbsp;
                      </>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {item?.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <ReceiveInInput
          receiveInValue={receiveInValue}
          handleOrderRate={handleOrderRate}
          setMinAmountErrorMessage_={setMinAmountErrorMessage_}
          setMaxAmountErrorMessage_={setMaxAmountErrorMessage_}
          maxAmount_={maxAmount_}
          setReceiveInCurrency={setReceiveInCurrency}
          setReceiveInCurrencyImg={setReceiveInCurrencyImg}
          // setCurrencyTypeErrorMessage={setCurrencyTypeErrorMessage}
          setCurrencyType={setCurrencyType}
          setReceiveInCurrencyType={setReceiveInCurrencyType}
          setReceiveInValue={setReceiveInValue}
          receiveInCurrency={receiveInCurrency}
          receiveInCurrencyImg={receiveInCurrencyImg}
          supportedCurrencies={supportedCurrencies}
          setReceiveInNetwork={setReceiveInNetwork}
          payInCurrencyType={payInCurrencyType}
          setCryptoNetworkReceiveInValue={setCryptoNetworkReceiveInValue}
          cryptoNetworkReceiveInImg={cryptoNetworkReceiveInImg}
          setCryptoNetworkReceiveInImg={setCryptoNetworkReceiveInImg}
          cryptoNetworkReceiveInValue={cryptoNetworkReceiveInValue}
          activeCurrencyType={activeCurrencyType}
        />
      </div>

      {minAmountErrorMessage && (
        <small className="text-danger d-block">
          *Minimum purchase amount: {1} {payInCurrency}*
        </small>
      )}

      {maxAmountErrorMessage && (
        <small className="text-danger">
          *Maximum purchase amount: {maxAmount} {payInCurrency}*
        </small>
      )}

      {minAmountErrorMessage_ && (
        <small className="text-danger d-block">
          *Minimum purchase amount: {1} {receiveInCurrency}*
        </small>
      )}

      {maxAmountErrorMessage_ && (
        <small className="text-danger">
          *Maximum purchase amount: {maxAmount_} {receiveInCurrency}*
        </small>
      )}

      {rateError && <small className="text-danger">*{rateError}*</small>}
      {/* {currencyTypeErrorMessage && (
        <small className="text-danger">*Currency Type Does Not Match*</small>
      )} */}

      <div className="d-flex justify-content-between">
        {" "}
        {startingRate && (
          <div className="rate ">
            <span>Starting Rate</span>
            <p>
              1 {payInCurrency} ~ {startingRate} {receiveInCurrency}
            </p>
          </div>
        )}
        {bestRate && (
          <div className="rate ">
            <span>Best Rate</span>
            <p>
              1 {payInCurrency} ~ {bestRate} {receiveInCurrency}
            </p>
          </div>
        )}
      </div>
      <div className="d-grid mt-4 gap-2">
        <Button
          variant="primary"
          className="py-2 btn-style"
          size="md"
          disabled={
            !payInValue ||
            !receiveInValue ||
            receiveInValue < 1 ||
            receiveInValue > maxAmount_ ||
            payInValue < 1 ||
            payInValue > maxAmount
            // currencyTypeErrorMessage
          }
          onClick={() => (isLoggedIn ? submitHandler() : setModalType("Login"))}
        >
          Swap
        </Button>
      </div>
    </div>
  );
};

export default SwapOrderForm;
