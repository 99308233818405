import React from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const TransHashModal = ({
  modal,
  setModalType,
  setTransHash,
  onSubmit,
  transHash,
}) => {
  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="py-4 px-3">
          <div className="form-group mt-3">
            <label className="form-label mb-0" htmlFor="acctNo">
              Transaction Hash
            </label>{" "}
            <input
              type="text"
              className="form-control"
              placeholder="Please enter your Transaction Hash"
              onChange={(e) => setTransHash(e.target.value)}
            />
          </div>
          <Button
            variant="primary"
            className="py-2 px-5 mt-3 btn-style"
            size="md"
            onClick={() => {
              if (transHash === "") {
                toast.error("Input field cannot be blank");
              } else {
                setModalType();
                onSubmit();
              }
            }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransHashModal;
