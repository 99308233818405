import React, { useContext, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import logo from "../../images/logo2.png";
import { AuthContext } from "../../contexts/AuthContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
const google = (window.google = window.google ? window.google : {});

const LoginModal = ({ modal, setModalType }) => {
  let navigate = useNavigate();
  // Start Context
  const { loginCustomer, googleAuth } = useContext(AuthContext);
  const { getPendingOrder } = useContext(GlobalContext);

  // End Context

  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState();

  const dashboardRoute = (pendingOrder) => {
    if (pendingOrder?.orderResponse) {
      if (
        pendingOrder?.orderResponse?.orderType === "Buy" &&
        pendingOrder?.orderResponse?.p2POrderStatus === "Acknowledged"
      ) {
        navigate("/view-pending-request");
        setModalType("LoginSuccess");
      } else if (
        pendingOrder?.orderResponse?.orderType === "Sell" &&
        pendingOrder?.orderResponse?.p2POrderStatus === "Acknowledged"
      ) {
        navigate("/pending-request");
        setModalType("LoginSuccess");
      } else {
        setModalType("LoginSuccess");
      }
    } else {
      setModalType("LoginSuccess");
    }
  };
  const submitHandler = async () => {
    if (!email || !password) {
      toast.error("Input fields cannot be blank");
    } else {
      setIsButtonLoading(true);
      try {
        let payload = {
          email,
          password,
          loggedInAs: "Customer",
        };

        let response = await loginCustomer(payload);
        if (response?.data) {
          let pendingOrder = await getPendingOrder();
          dashboardRoute(pendingOrder);
        } else {
          toast.error(response?.Message || "Oops, Something went wrong");
        }
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }

      setIsButtonLoading(false);
    }
  };

  const handleCredentialResponse = async (response) => {
    let payload = {
      authProvider: "Google",
      idToken: response?.credential,
    };
    let resp = await googleAuth(payload);
    if (resp?.data) {
      let pendingOrder = await getPendingOrder();
      dashboardRoute(pendingOrder);
    }
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "940228248038-n2qismbiq1vvvvvf2levgs6p323gqa79.apps.googleusercontent.com",
      callback: (data) => handleCredentialResponse(data),
    });

    // Display the One Tap prompt
    google.accounts.id.prompt();

    google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
      theme: "outline",
      size: "large",
    });
  }, []);

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-4 px-4  text-center ">
          <div>
            <img src={logo} alt="logo" height={40} />
            <h3 className="py-3">Log In</h3>
            <p>Enter your email and password</p>
            <div className="form-group mt-5">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <a
              href="/#"
              className="d-flex justify-content-end mb-4  close ms-1"
              onClick={() => setModalType("ForgotPassword")}
            >
              Forgot password?
            </a>
            <div className="terms text-center ">
              By Loging into XendBridge, you agree to the{" "}
              <a href="/#" style={{ color: "#797979" }}>
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="/#" style={{ color: "#797979" }}>
                Privacy Policy
              </a>
              .
            </div>

            <div className="d-grid gap-2 mt-4 mb-3 ">
              {" "}
              {isButtonLoading ? (
                <Button
                  disabled
                  variant="primary "
                  className="py-2 btn-style"
                  size="md"
                >
                  <i
                    className="fa fa-spinner fa-pulse mx-2 "
                    aria-hidden="true"
                  ></i>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="py-2 btn-style"
                  size="md"
                  onClick={() => submitHandler()}
                >
                  Agree & Continue
                </Button>
              )}
            </div>

            <h5 className="text-center">OR</h5>
            <div className="mt-3 mb-4 d-flex justify-content-center">
              <div id="buttonDiv"></div>
            </div>

            <div className=" text-center">
              <a
                className="btn  py-2 close"
                href="/#"
                onClick={() => setModalType()}
              >
                Close
              </a>
              <div className="terms mt-3 ">
                Don't have an account?{" "}
                <a
                  href="/#"
                  className=" close ms-1"
                  onClick={() => setModalType("Signup")}
                >
                  Create a free account
                </a>{" "}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
