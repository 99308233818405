import React, { useContext, useEffect, useState } from "react";
import BuyReceivingDetailsForm from "../components/BuyReceivingDetailsForm";
import SellReceivingDetailsForm from "../components/SellReceivingDetailsForm";
import InitiateOrder from "../components/InitiateOrder";
import BuyConfirmPurchase from "../components/BuyConfirmPurchase";
import SellConfirmPurchase from "../components/SellConfirmPurchase";
import BuyCrypto from "../components/BuyCrypto";
import SellCrypto from "../components/SellCrypto";
import OrderSuccess from "../components/OrderSuccess";
import BuyPaymentDetailsForm from "../components/BuyPaymentDetailsForm";
import SellPaymentDetailsForm from "../components/SellPaymentDetailsForm";
import { GlobalContext } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import PayPaymentDetailsForm from "../components/Swap/Fiat/PayPaymentDetailsForm";
import ReceivePaymentDetailsForm from "../components/Swap/Fiat/ReceivePaymentDetailsForm";
import FiatConfirmPurchase from "../components/Swap/Fiat/FiatConfirmPurchase";
import BuyFiat from "../components/Swap/Fiat/BuyFiat";
import BuyCrypto2 from "../components/Swap/Crypto/BuyCrypto_";
import CryptoConfirmPurchase from "../components/Swap/Crypto/CryptoConfirmPurchase";
import ReceiveCryptoPaymentDetailsForm from "../components/Swap/Crypto/ReceiveCryptoPaymentDetailsForm";
import PayCryptoPaymentDetailsForm from "../components/Swap/Crypto/PayCryptoPaymentDetailsForm";
import { cryptoNetworks } from "../utils/cryptoNetworks";

function Index({
  setModalType,
  setEmailOrPhone,
  setVerificationReference,
  modalType,
  setDisputeStatus,
  setOrderReference: setOrderReference_,
  setDisputeReference,
}) {
  let navigate = useNavigate();
  const {
    ongoingTrans,
    getSupportedCurrencies,
    supportedCurrencies,
    setPayInCurrency,
    setPayInCurrencyImg,
    setPayInNetwork,
    setReceiveInCurrency,
    setReceiveInCurrencyImg,
    setReceiveInNetwork,

    setCryptoNetworkImg,
    setCryptoNetworkValue,
    setCryptoNetworkReceiveInValue,
    setCryptoNetworkReceiveInImg,
    setCryptoNetworkPayInValue,
    setCryptoNetworkPayInImg,
  } = useContext(GlobalContext);

  const [activePage, setActivePage] = useState("initiateOrder");
  const [isActive, setIsActive] = useState("buy");
  const [orderType, setOrderType] = useState();
  const [fiatValue, setFiatValue] = useState("");
  const [cryptoValue, setCryptoValue] = useState("");
  const [payInValue, setPayInValue] = useState("");
  const [receiveInValue, setReceiveInValue] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [fiatPaymentType, setFiatPaymentType] = useState();
  const [fiatPaymentMethod, setFiatPaymentMethod] = useState();
  const [orderAcctName, setOrderAcctName] = useState();
  const [orderAcctNumber, setOrderAcctNumber] = useState();
  const [orderBankName, setOrderBankName] = useState();
  const [orderAcctId, setOrderAcctId] = useState();
  const [orderReference, setOrderReference] = useState();
  const [orderWalletAddress, setOrderWalletAddress] = useState();
  const [orderPhoneNo, setOrderPhoneNo] = useState();
  const [fiatPayment, setFiatPayment] = useState();

  const [emailAddress, setEmailAddress] = useState("");
  const [fintechCurrency, setFintechCurrency] = useState("");
  const [mobileMoneyCurrency, setMobileMoneyCurrency] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [acctNumber, setAcctNumber] = useState("");
  const [acctName, setAcctName] = useState("");

  // Receiving Details
  const [emailAddress_, setEmailAddress_] = useState("");
  const [fintechCurrency_, setFintechCurrency_] = useState("");
  const [mobileMoneyCurrency_, setMobileMoneyCurrency_] = useState("");
  const [phoneNo_, setPhoneNo_] = useState("");
  const [bankName_, setBankName_] = useState("");
  const [acctNumber_, setAcctNumber_] = useState("");
  const [acctName_, setAcctName_] = useState("");

  const [receivePaymentMethod, setReceivePaymentMethod] = useState();
  const [payPaymentMethod, setPayPaymentMethod] = useState();
  const [receivePaymentType, setReceivePaymentType] = useState();
  const [receivePayment, setReceivePayment] = useState();
  const [payPaymentType, setPayPaymentType] = useState();
  const [payPayment, setPayPayment] = useState();
  const [payWalletAddress, setPayWalletAddress] = useState("");
  const [receiveWalletAddress, setReceiveWalletAddress] = useState("");

  useEffect(() => {
    getSupportedCurrencies();
  }, []);

  const resetForm = () => {
    let crypto = supportedCurrencies?.filter(
      (item) => item?.currencyType === "CRYPTO" && item
    );

    let fiat = supportedCurrencies?.filter(
      (item) => item?.currencyType === "FIAT" && item
    );

    setPayInCurrency(crypto[0]?.currencySymbol);
    setPayInCurrencyImg(crypto[0]?.symbolUrl);
    setPayInNetwork(crypto[0]?.network);
    setReceiveInCurrency(crypto[1]?.currencySymbol);
    setReceiveInCurrencyImg(crypto[1]?.symbolUrl);
    setReceiveInNetwork(crypto[1]?.network);
    setPayInCurrency(fiat[0]?.currencySymbol);
    setPayInCurrencyImg(fiat[0]?.symbolUrl);
    setPayInNetwork(fiat[0]?.network);
    setReceiveInCurrency(fiat[1]?.currencySymbol);
    setReceiveInCurrencyImg(fiat[1]?.symbolUrl);
    setReceiveInNetwork(fiat[1]?.network);

    setCryptoNetworkImg(cryptoNetworks[0]?.symbolUrl);
    setCryptoNetworkValue(cryptoNetworks[0]?.name);
    setCryptoNetworkReceiveInImg(cryptoNetworks[0]?.symbolUrl);
    setCryptoNetworkReceiveInValue(cryptoNetworks[0]?.name);
    setCryptoNetworkPayInImg(cryptoNetworks[1]?.symbolUrl);
    setCryptoNetworkPayInValue(cryptoNetworks[1]?.name);

    setEmailOrPhone();
    setVerificationReference();
    setFiatValue();
    setCryptoValue();
    setWalletAddress();
    setFiatPaymentType();
    setBankName();
    setAcctName();
    setAcctNumber();
    setMobileMoneyCurrency();
    setPhoneNo();
    setPayInCurrency();
  };

  if (ongoingTrans) {
    return navigate("/order-requests");
  }

  return (
    <>
      {activePage === "initiateOrder" && (
        <InitiateOrder
          setActivePage={setActivePage}
          setModalType={setModalType}
          setFiatValue={setFiatValue}
          setCryptoValue={setCryptoValue}
          fiatValue={fiatValue}
          cryptoValue={cryptoValue}
          setIsActive={setIsActive}
          isActive={isActive}
          resetForm={resetForm}
          setPayInValue={setPayInValue}
          setReceiveInValue={setReceiveInValue}
          payInValue={payInValue}
          receiveInValue={receiveInValue}
        />
      )}
      {activePage === "buyReceivingDetailsForm" && (
        <BuyReceivingDetailsForm
          setActivePage={setActivePage}
          walletAddress={walletAddress}
          setWalletAddress={setWalletAddress}
        />
      )}
      {activePage === "buyPaymentDetailsForm" && (
        <BuyPaymentDetailsForm
          setActivePage={setActivePage}
          walletAddress={walletAddress}
          setWalletAddress={setWalletAddress}
          setAcctName={setAcctName}
          setAcctNumber={setAcctNumber}
          setBankName={setBankName}
          setFiatPaymentType={setFiatPaymentType}
          fiatPaymentType={fiatPaymentType}
          bankName={bankName}
          acctName={acctName}
          acctNumber={acctNumber}
          setFiatPaymentMethod={setFiatPaymentMethod}
          fiatPayment={fiatPayment}
          setFiatPayment={setFiatPayment}
          setMobileMoneyCurrency={setMobileMoneyCurrency}
          setPhoneNo={setPhoneNo}
          mobileMoneyCurrency={mobileMoneyCurrency}
          phoneNo={phoneNo}
          setEmailAddress={setEmailAddress}
          setFintechCurrency={setFintechCurrency}
          emailAddress={emailAddress}
          fintechCurrency={fintechCurrency}
        />
      )}
      {activePage === "sellPaymentDetailsForm" && (
        <SellPaymentDetailsForm
          setActivePage={setActivePage}
          walletAddress={walletAddress}
          setWalletAddress={setWalletAddress}
          setIsActive={setIsActive}
        />
      )}
      {activePage === "sellReceivingDetailsForm" && (
        <SellReceivingDetailsForm
          setActivePage={setActivePage}
          walletAddress={walletAddress}
          setWalletAddress={setWalletAddress}
          setAcctName={setAcctName}
          setAcctNumber={setAcctNumber}
          setBankName={setBankName}
          setFiatPaymentType={setFiatPaymentType}
          fiatPaymentType={fiatPaymentType}
          bankName={bankName}
          acctName={acctName}
          acctNumber={acctNumber}
          setFiatPaymentMethod={setFiatPaymentMethod}
          fiatPayment={fiatPayment}
          setFiatPayment={setFiatPayment}
          setMobileMoneyCurrency={setMobileMoneyCurrency}
          setPhoneNo={setPhoneNo}
          mobileMoneyCurrency={mobileMoneyCurrency}
          phoneNo={phoneNo}
          setEmailAddress={setEmailAddress}
          setFintechCurrency={setFintechCurrency}
          emailAddress={emailAddress}
          fintechCurrency={fintechCurrency}
        />
      )}
      {activePage === "buyConfirmPurchase" && (
        <BuyConfirmPurchase
          setActivePage={setActivePage}
          walletAddress={walletAddress}
          fiatValue={fiatValue}
          cryptoValue={cryptoValue}
          bankName={bankName}
          acctName={acctName}
          acctNumber={acctNumber}
          fiatPaymentType={fiatPaymentType}
          setOrderReference={setOrderReference}
          setOrderAcctName={setOrderAcctName}
          setOrderAcctNumber={setOrderAcctNumber}
          setOrderBankName={setOrderBankName}
          setOrderAcctId={setOrderAcctId}
          setOrderPhoneNo={setOrderPhoneNo}
          fiatPaymentMethod={fiatPaymentMethod}
          phoneNo={phoneNo}
          emailAddress={emailAddress}
          fintechCurrency={fintechCurrency}
        />
      )}
      {activePage === "sellConfirmPurchase" && (
        <SellConfirmPurchase
          setActivePage={setActivePage}
          walletAddress={walletAddress}
          fiatValue={fiatValue}
          cryptoValue={cryptoValue}
          bankName={bankName}
          acctName={acctName}
          acctNumber={acctNumber}
          fiatPaymentType={fiatPaymentType}
          setOrderReference={setOrderReference}
          setOrderWalletAddress={setOrderWalletAddress}
          fiatPaymentMethod={fiatPaymentMethod}
          phoneNo={phoneNo}
          setOrderPhoneNo={setOrderPhoneNo}
          emailAddress={emailAddress}
          fintechCurrency={fintechCurrency}
        />
      )}
      {activePage === "buyCrypto" && (
        <BuyCrypto
          setActivePage={setActivePage}
          setModalType={setModalType}
          setOrderType={setOrderType}
          fiatValue={fiatValue}
          cryptoValue={cryptoValue}
          orderReference={orderReference}
          orderAcctName={orderAcctName}
          orderAcctNumber={orderAcctNumber}
          orderBankName={orderBankName}
          orderPhoneNo={orderPhoneNo}
          orderAcctId={orderAcctId}
          fiatPaymentMethod={fiatPaymentMethod}
          setDisputeStatus={setDisputeStatus}
          setOrderReference={setOrderReference_}
          setDisputeReference={setDisputeReference}
        />
      )}
      {activePage === "sellCrypto" && (
        <SellCrypto
          setActivePage={setActivePage}
          setModalType={setModalType}
          setOrderType={setOrderType}
          fiatValue={fiatValue}
          cryptoValue={cryptoValue}
          orderWalletAddress={orderWalletAddress}
          orderReference={orderReference}
          modalType={modalType}
          setDisputeStatus={setDisputeStatus}
          setOrderReference={setOrderReference_}
          setDisputeReference={setDisputeReference}
        />
      )}
      {activePage === "orderSuccess" && (
        <OrderSuccess
          orderType={orderType}
          setActivePage={setActivePage}
          setModalType={setModalType}
          resetForm={resetForm}
          orderReference={orderReference}
          setDisputeStatus={setDisputeStatus}
          setOrderReference={setOrderReference_}
          setDisputeReference={setDisputeReference}
        />
      )}

      {activePage === "payPaymentDetailsForm" && (
        <PayPaymentDetailsForm
          setActivePage={setActivePage}
          setPayPaymentType={setPayPaymentType}
          setAcctName={setAcctName}
          setAcctNumber={setAcctNumber}
          setBankName={setBankName}
          payPaymentType={payPaymentType}
          setPayPaymentMethod={setPayPaymentMethod}
          bankName={bankName}
          acctName={acctName}
          acctNumber={acctNumber}
          payPayment={payPayment}
          setPayPayment={setPayPayment}
          setMobileMoneyCurrency={setMobileMoneyCurrency}
          setPhoneNo={setPhoneNo}
          phoneNo={phoneNo}
          setEmailAddress={setEmailAddress}
          setFintechCurrency={setFintechCurrency}
          emailAddress={emailAddress}
        />
      )}

      {activePage === "receivePaymentDetailsForm" && (
        <ReceivePaymentDetailsForm
          setActivePage={setActivePage}
          setReceivePaymentType={setReceivePaymentType}
          setAcctName={setAcctName_}
          setAcctNumber={setAcctNumber_}
          setBankName={setBankName_}
          receivePaymentType={receivePaymentType}
          setReceivePaymentMethod={setReceivePaymentMethod}
          bankName={bankName_}
          acctName={acctName_}
          acctNumber={acctNumber_}
          receivePayment={receivePayment}
          setReceivePayment={setReceivePayment}
          setMobileMoneyCurrency={setMobileMoneyCurrency_}
          setPhoneNo={setPhoneNo_}
          phoneNo={phoneNo_}
          setEmailAddress={setEmailAddress_}
          setFintechCurrency={setFintechCurrency_}
          emailAddress={emailAddress_}
          fintechCurrency={fintechCurrency_}
          mobileMoneyCurrency={mobileMoneyCurrency_}
        />
      )}

      {activePage === "fiatConfirmPurchase" && (
        <FiatConfirmPurchase
          setActivePage={setActivePage}
          bankName_={bankName_}
          acctName_={acctName_}
          acctNumber_={acctNumber_}
          phoneNo_={phoneNo_}
          emailAddress-={emailAddress_}
          bankName={bankName}
          acctName={acctName}
          acctNumber={acctNumber}
          phoneNo={phoneNo}
          emailAddress={emailAddress}
          setOrderReference={setOrderReference}
          setOrderAcctName={setOrderAcctName}
          setOrderAcctNumber={setOrderAcctNumber}
          setOrderBankName={setOrderBankName}
          setOrderAcctId={setOrderAcctId}
          setOrderPhoneNo={setOrderPhoneNo}
          receiveInValue={receiveInValue}
          receivePaymentType={receivePaymentType}
          receivePaymentMethod={receivePaymentMethod}
          payPaymentType={payPaymentType}
          payPaymentMethod={payPaymentMethod}
          payInValue={payInValue}
        />
      )}

      {activePage === "buyFiat" && (
        <BuyFiat
          setActivePage={setActivePage}
          setModalType={setModalType}
          setOrderType={setOrderType}
          payInValue={payInValue}
          receiveInValue={receiveInValue}
          orderReference={orderReference}
          orderAcctName={orderAcctName}
          orderAcctNumber={orderAcctNumber}
          orderBankName={orderBankName}
          orderPhoneNo={orderPhoneNo}
          orderAcctId={orderAcctId}
          payPaymentMethod={payPaymentMethod}
          setDisputeStatus={setDisputeStatus}
          setOrderReference={setOrderReference_}
          setDisputeReference={setDisputeReference}
        />
      )}

      {activePage === "payCryptoPaymentDetailsForm" && (
        <PayCryptoPaymentDetailsForm
          setActivePage={setActivePage}
          setPayWalletAddress={setPayWalletAddress}
          payWalletAddress={payWalletAddress}
          setIsActive={setIsActive}
        />
      )}

      {activePage === "receiveCryptoPaymentDetailsForm" && (
        <ReceiveCryptoPaymentDetailsForm
          setActivePage={setActivePage}
          setReceiveWalletAddress={setReceiveWalletAddress}
          receiveWalletAddress={receiveWalletAddress}
          setIsActive={setIsActive}
        />
      )}

      {activePage === "cryptoConfirmPurchase" && (
        <CryptoConfirmPurchase
          setActivePage={setActivePage}
          payInValue={payInValue}
          receiveInValue={receiveInValue}
          payWalletAddress={payWalletAddress}
          receiveWalletAddress={receiveWalletAddress}
          setOrderReference={setOrderReference}
          setOrderWalletAddress={setOrderWalletAddress}
        />
      )}

      {activePage === "buyCrypto_" && (
        <BuyCrypto2
          setActivePage={setActivePage}
          setModalType={setModalType}
          setOrderType={setOrderType}
          modalType={modalType}
          setDisputeStatus={setDisputeStatus}
          setOrderReference={setOrderReference_}
          setDisputeReference={setDisputeReference}
        />
      )}
    </>
  );
}

export default Index;
