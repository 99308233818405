import React from "react";
import { FormControl, InputGroup, Dropdown } from "react-bootstrap";

const Fiat = ({
  fiatValue,
  setMinAmountErrorMessage,
  setMaxAmountErrorMessage,
  maxAmount,
  handleOrderRate,
  cryptoValue,
  fiatCurrencies,
  fiatCurrency,
  fiatCurrencyImg,
  setFiatCurrency,
  setFiatCurrencyImg,
  setFiatNetwork,
  setFiatValue,
}) => {
  return (
    <>
      {" "}
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Enter amount"
          className="input-width-2"
          value={fiatValue}
          onChange={(e) => {
            handleOrderRate("FIAT", e.target.value);
            if (cryptoValue < 1) {
              setMinAmountErrorMessage(true);
            } else if (cryptoValue > maxAmount) {
              setMaxAmountErrorMessage(true);
            } else {
              setMinAmountErrorMessage(false);
              setMaxAmountErrorMessage(false);
            }
          }}
        />
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            as="div"
            className="form-control style-1 default-select text-dark-black d-flex align-items-center   p-2 pe-3 "
            style={{ fontSize: "12px" }}
          >
            {fiatCurrencyImg && (
              <img src={fiatCurrencyImg} alt="currencyImage" height={13} />
            )}
            &nbsp;&nbsp;{fiatCurrency}
          </Dropdown.Toggle>{" "}
          <Dropdown.Menu>
            {fiatCurrencies?.map((item, i) => (
              <Dropdown.Item
                style={{ fontSize: "12px", padding: " 0.7rem 1.2rem" }}
                onClick={() => {
                  setFiatValue("");
                  setFiatCurrency(item?.currencySymbol);
                  setFiatCurrencyImg(item?.symbolUrl);
                  setFiatNetwork(item?.network);
                }}
                key={i}
              >
                {item?.symbolUrl && (
                  <>
                    <img
                      src={item?.symbolUrl}
                      alt="currencyImage"
                      height={13}
                    />
                    &nbsp;&nbsp;
                  </>
                )}{" "}
                <strong>{item?.currencySymbol}</strong>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {item?.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    </>
  );
};

export default Fiat;
