import React, { createContext, useState } from "react";
import {
  createSellOrder,
  createBuyOrder,
  createSwapOrder,
  createConfirmOrderRequest,
  createRaiseOrderRequestDispute,
  createCancelOrderRequest,
} from "../apis/Create.js";
import {
  readSupportedCurrencies,
  readOrderRate,
  readOrderRequests,
  readPendingOrderRequest,
  readPaymentMethods,
  readOrderRequest,
  readLiveTransactions,
  readOrderRateBounds,
  readBankList,
} from "../apis/Read.js";
import { cryptoNetworks } from "../utils/cryptoNetworks.js";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [fiatCurrency, setFiatCurrency] = useState();
  const [fiatCurrencyImg, setFiatCurrencyImg] = useState();
  const [cryptoCurrency, setCryptoCurrency] = useState();
  const [fiatNetwork, setFiatNetwork] = useState();
  const [cryptoNetwork, setCryptoNetwork] = useState();
  const [cryptoCurrencyImg, setCryptoCurrencyImg] = useState();
  const [cryptoNetworkImg, setCryptoNetworkImg] = useState();
  const [allOrderRequests, setAllOrderRequests] = useState([]);
  const [ongoingTrans, setOngoingTrans] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [liveTransactions, setLiveTransactions] = useState([]);
  const [bestRate, setBestRate] = useState();
  const [pendingOrder, setPendingOrder] = useState();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [startingRate, setStartingRate] = useState();
  const [rateError, setRateError] = useState();
  const [payInCurrency, setPayInCurrency] = useState();
  const [payInCurrencyNetwork, setPayInNetwork] = useState();
  const [payInCurrencyImg, setPayInCurrencyImg] = useState();
  const [receiveInCurrency, setReceiveInCurrency] = useState();
  const [receiveInNetwork, setReceiveInNetwork] = useState();
  const [receiveInCurrencyImg, setReceiveInCurrencyImg] = useState();
  const [cryptoNetworkValue, setCryptoNetworkValue] = useState("");
  const [cryptoNetworkReceiveInImg, setCryptoNetworkReceiveInImg] = useState();
  const [cryptoNetworkReceiveInValue, setCryptoNetworkReceiveInValue] =
    useState();
  const [cryptoNetworkPayInImg, setCryptoNetworkPayInImg] = useState();
  const [cryptoNetworkPayInValue, setCryptoNetworkPayInValue] = useState();

  //  INITIATE SELL ORDER
  const initiateSellOrder = async (payload) => {
    return await createSellOrder(payload);
  };

  //  INITIATE BUY ORDER
  const initiateBuyOrder = async (payload) => {
    return await createBuyOrder(payload);
  };

  //  INITIATE SWAP ORDER
  const initiateSwapOrder = async (payload) => {
    return await createSwapOrder(payload);
  };

  //  GET SUPPORTED CURRENCIES
  const getSupportedCurrencies = async () => {
    let res = await readSupportedCurrencies();
    if (res?.data) {
      let crypto = res?.data?.filter(
        (item) => item?.currencyType === "CRYPTO" && item
      );
      setCryptoCurrencies(crypto);

      let fiat = res?.data?.filter(
        (item) => item?.currencyType === "FIAT" && item
      );
      setFiatCurrencies(fiat);

      setFiatCurrency(fiatCurrency || fiat[0]?.currencySymbol);
      setFiatCurrencyImg(fiatCurrencyImg || fiat[0]?.symbolUrl);
      setFiatNetwork(fiatNetwork || fiat[0]?.network);
      setCryptoCurrency(cryptoCurrency || crypto[0]?.currencySymbol);
      setCryptoCurrencyImg(cryptoCurrencyImg || crypto[0]?.symbolUrl);
      setCryptoNetwork(cryptoNetwork || crypto[0]?.network);
      setCryptoNetworkImg(cryptoNetworkImg || cryptoNetworks[0]?.symbolUrl);
      setCryptoNetworkValue(cryptoNetworkValue || cryptoNetworks[0]?.name);
      setCryptoNetworkReceiveInImg(
        cryptoNetworkImg || cryptoNetworks[0]?.symbolUrl
      );
      setCryptoNetworkReceiveInValue(
        cryptoNetworkValue || cryptoNetworks[0]?.name
      );

      setCryptoNetworkPayInImg(
        cryptoNetworkImg || cryptoNetworks[1]?.symbolUrl
      );
      setCryptoNetworkPayInValue(cryptoNetworkValue || cryptoNetworks[1]?.name);

      setPayInCurrency(payInCurrency ? payInCurrency : fiat[0]?.currencySymbol);
      setPayInCurrencyImg(
        payInCurrencyImg ? payInCurrencyImg : fiat[0]?.symbolUrl
      );
      setPayInNetwork(
        payInCurrencyNetwork ? payInCurrencyNetwork : fiat[0]?.network
      );

      setReceiveInCurrency(
        receiveInCurrency ? receiveInCurrency : fiat[1]?.currencySymbol
      );
      setReceiveInCurrencyImg(
        receiveInCurrencyImg ? receiveInCurrencyImg : fiat[1]?.symbolUrl
      );
      setReceiveInNetwork(
        receiveInNetwork ? receiveInNetwork : fiat[1]?.network
      );

      setSupportedCurrencies(res?.data);
    }
  };

  //  GET ORDER RATE
  const getOrderRate = async (payload) => {
    return await readOrderRate(payload);
  };

  //  GET BEST ORDER RATE
  const getBestRate = async (
    cryptoCurrency,
    fiatCurrency,
    cryptoNetwork,
    fiatNetwork,
    type,
    payInCurrency,
    payInCurrencyNetwork,
    receiveInCurrency,
    receiveInNetwork
  ) => {
    let _payload = {
      payInCurrencyCode: payInCurrency,
      payInCurrencyNetwork: payInCurrencyNetwork,
      receiveInCurrencyCode: receiveInCurrency,
      receiveInCurrencyNetwork: receiveInNetwork,
      orderAmount: "",
    };

    let payload = {
      payInCurrencyCode: cryptoCurrency,
      payInCurrencyNetwork: cryptoNetwork,
      receiveInCurrencyCode: fiatCurrency,
      receiveInCurrencyNetwork: fiatNetwork,
      orderAmount: "",
    };

    let payload_ = {
      payInCurrencyCode: fiatCurrency,
      payInCurrencyNetwork: fiatNetwork,
      receiveInCurrencyCode: cryptoCurrency,
      receiveInCurrencyNetwork: cryptoNetwork,
      orderAmount: "",
    };
    let res;

    if (type === "sell") {
      res = await readOrderRateBounds(payload);
    }

    if (type === "buy") {
      res = await readOrderRateBounds(payload_);
    }

    if (type === "swap") {
      res = await readOrderRateBounds(_payload);
    }

    if (res?.data) {
      setBestRate(res?.data?.bestRate?.exchangeRate);
      setStartingRate(res?.data?.worstRate?.exchangeRate);
      setRateError();
      return res;
    } else {
      setBestRate();
      setStartingRate();
      setRateError(res?.Message);
    }
  };

  // GET ALL ORDER REQUESTS
  const getOrderRequests = async (page, getHistoryAcrossApps) => {
    setIsPageLoading(true);
    let payload = {
      page,
      getHistoryAcrossApps,
    };
    const res = await readOrderRequests(payload);
    if (res?.data) {
      setIsPageLoading(false);
      setAllOrderRequests(res?.data);
      if (res?.data.records.length < 1) {
        setOngoingTrans(false);
      } else if (
        res?.data?.records?.[0]?.orderStatus === "Cancelled" ||
        res?.data?.records?.[0]?.orderStatus === "Completed"
      ) {
        setOngoingTrans(false);
      } else {
        setOngoingTrans(true);
      }
    }
    setIsPageLoading(false);
  };

  // GET ALL NEW ORDER REQUESTS
  const getNewOrderRequests = async (page, getHistoryAcrossApps) => {
    let payload = {
      page,
      getHistoryAcrossApps,
    };
    const res = await readOrderRequests(payload);
    if (res?.data) {
      setAllOrderRequests(res?.data);
    }
  };

  // GET PENDING ORDER REQUEST
  const getPendingOrder = async () => {
    setIsPageLoading(true);

    const res = await readPendingOrderRequest();
    if (res?.data) {
      setPendingOrder(res?.data);
    }
    setIsPageLoading(false);
    return res?.data;
  };

  // GET NEW PENDING ORDER REQUEST
  const getNewPendingOrder = async () => {
    const res = await readPendingOrderRequest();
    if (res?.data) {
      setPendingOrder(res?.data);
    }
  };

  //  GET ORDER REQUEST
  const getOrderRequest = async (orderReference) => {
    let payload = {
      orderReference,
    };
    return await readOrderRequest(payload);
  };

  //  CONFIRM ORDER REQUEST
  const confirmOrder = async (payload) => {
    return await createConfirmOrderRequest(payload);
  };

  //  CANCEL ORDER REQUEST
  const cancelOrder = async (payload) => {
    return await createCancelOrderRequest(payload);
  };

  //  RAISE A DISPUTE FOR AN ORDER REQUEST
  const raiseDispute = async (payload) => {
    return await createRaiseOrderRequestDispute(payload);
  };

  // GET PAYMENT METHODS
  const getPaymentMethods = async (currencyType) => {
    let payload = {
      currencyType,
    };
    const res = await readPaymentMethods(payload);
    if (res?.data) {
      setPaymentMethods(res?.data);
    }
  };

  // GET PAYMENT METHODS
  const getBankList = async () => {
    const res = await readBankList();
    if (res?.data) {
      setBankList(res?.data);
    }
  };

  // GET LIVE  TRANSACTIONS
  const getLiveTransactions = async () => {
    const res = await readLiveTransactions();
    if (res?.data) {
      setLiveTransactions(res?.data);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        initiateSellOrder,
        getSupportedCurrencies,
        supportedCurrencies,
        getOrderRate,
        getBestRate,
        bestRate,
        initiateBuyOrder,
        getOrderRequests,
        allOrderRequests,
        isPageLoading,
        pendingOrder,
        getPendingOrder,
        confirmOrder,
        fiatCurrencies,
        cryptoCurrencies,
        fiatCurrency,
        fiatCurrencyImg,
        cryptoCurrency,
        cryptoCurrencyImg,
        setCryptoCurrencyImg,
        setCryptoCurrency,
        setFiatCurrencyImg,
        setFiatCurrency,
        getPaymentMethods,
        paymentMethods,
        setCryptoNetwork,
        bankList,
        setFiatNetwork,
        fiatNetwork,
        cryptoNetwork,
        getOrderRequest,
        liveTransactions,
        getLiveTransactions,
        getNewOrderRequests,
        raiseDispute,
        getBankList,
        getNewPendingOrder,
        ongoingTrans,
        cancelOrder,
        startingRate,
        rateError,
        payInCurrency,
        payInCurrencyImg,
        payInCurrencyNetwork,
        receiveInCurrency,
        receiveInCurrencyImg,
        receiveInNetwork,
        setPayInCurrency,
        setPayInCurrencyImg,
        setPayInNetwork,
        setReceiveInCurrency,
        setReceiveInCurrencyImg,
        setReceiveInNetwork,
        initiateSwapOrder,
        setCryptoNetworkImg,
        cryptoNetworkImg,
        cryptoNetworkValue,
        setCryptoNetworkValue,
        setCryptoNetworkReceiveInValue,
        cryptoNetworkReceiveInImg,
        setCryptoNetworkReceiveInImg,
        cryptoNetworkReceiveInValue,
        setCryptoNetworkPayInValue,
        cryptoNetworkPayInImg,
        setCryptoNetworkPayInImg,
        cryptoNetworkPayInValue,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
