import React, { useContext, useEffect, useState } from "react";
import logo from "../images/xendbr.svg";
import chat from "../images/Outline.svg";
import avatar from "../images/logo2.png";
import userAvatar from "../images/avatar.svg";
import add from "../images/add.svg";
import send from "../images/send.svg";
import cirlcStar from "../images/circle-star.svg";
import { getMessageHistory, sendMessage } from "xendbridgechat-sdk";
import { formatTime, getItemFromSessionStorage } from "../utils/fx";
import PageLoader from "./PageLoader";
import { GlobalContext } from "../contexts/GlobalContext";

const Appeal = ({ type, disputeStatus, orderReference, disputeReference }) => {
  // Start Context
  const { raiseDispute } = useContext(GlobalContext);
  // End Context

  const [publicKey] = useState("XLPUK_95BAFDA4E7874C7CB9A3A0CBFF08CB90");
  const [chats, getAllChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [base64Attachment, setBase64Attachment] = useState("");

  const getMessages = async () => {
    await getMessageHistory(disputeReference, publicKey, getAllChats);
  };

  const startApp = async () => {
    setIsLoading(true);
    getMessages();
    setIsLoading(false);
  };

  useEffect(() => {
    startApp();

    const intervalCall = setInterval(() => {
      startApp();
    }, 1000); //1 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMessages]);

  const addComment = async (e) => {
    e.preventDefault();
    if (comment === "") {
      setComment("");
      setBase64Attachment("");
    } else if (disputeStatus === "NoDispute") {
      let payload = {
        orderReference,
        remark: comment,
        base64Attachment: base64Attachment,
      };
      raiseDispute(payload);
      getMessages();
      setComment("");
      setBase64Attachment("");
    } else {
      let ChatHubAddCommentModel = {
        EmailAddress: getItemFromSessionStorage("userInfo")?.emailAddress,
        Comment: comment,
        SentBy: 2,
        Recipient: 1,
        Base64Attachment: base64Attachment,
        DisputeReference: disputeReference,
        SentAt: new Date().toISOString(),
      };

      await sendMessage(ChatHubAddCommentModel, publicKey, getAllChats);

      setComment("");
      setBase64Attachment("");
    }
  };

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        res(reader.result);
      });
      reader.readAsDataURL(image);
    });
  };

  const uploadImage = async (e) => {
    if (e.target.files) {
      const newImage = await fileToDataUri(e.target.files[0]);
      setBase64Attachment(newImage);
    }
    e.target.value = "";
  };

  return (
    <div className="appeal">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {" "}
          <img src={chat} alt="chat" className="me-1" />
          <span className="text">Appeal</span>
        </div>
        <div className="d-flex">
          <img src={logo} alt="logo" className="me-1" />
          <img src={cirlcStar} alt="cirlcStar" />
        </div>
      </div>
      <div className="chat pb-0 mt-3 px-0 pt-3 position-relative">
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {orderReference && chats?.length < 1 ? (
              <div className="row px-3">
                {/* Default Chat */}
                <div
                  className={`col-md-10 ${
                    type === "mobile" && "me-sm-4"
                  }  mb-3 "`}
                >
                  <div className="d-flex">
                    <div>
                      {" "}
                      <img
                        src={avatar}
                        alt="avatar"
                        height={20}
                        className="me-2 mt-4"
                      />
                    </div>{" "}
                    <div>
                      <div>
                        <span className="sender"> XendBridge</span>
                        &nbsp;&nbsp;&nbsp;
                        <span className="sender_time">2 mins ago </span>
                        <div className="mt-1 msg_cont p-2">
                          {" "}
                          Kindly let us know if you have an issue with this
                          transaction
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
                {/* Default Chat */}{" "}
              </div>
            ) : (
              <div className="row px-3">
                {chats?.map((item, index) => {
                  return (
                    <>
                      {item?.sentBy === 2 ? (
                        <>
                          {/* Start Customer */}
                          <div
                            className={`col-md-10 ${
                              type === "mobile" && "me-sm-4"
                            }  mb-3 "`}
                            key={index}
                          >
                            <div className="d-flex">
                              <div>
                                {" "}
                                <img
                                  src={userAvatar}
                                  alt="avatar"
                                  height={20}
                                  className="me-2 mt-4"
                                />
                              </div>{" "}
                              <div>
                                <div>
                                  <span className="sender">
                                    {" "}
                                    {item?.senderName}
                                  </span>
                                  &nbsp;&nbsp;&nbsp;
                                  <span className="sender_time">
                                    {" "}
                                    {formatTime(item?.receievedAtTimestamp)}
                                  </span>
                                  <div className="mt-1 msg_cont p-2">
                                    {item?.message}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2"></div>
                          {/* End Customer */}{" "}
                        </>
                      ) : (
                        <>
                          {/* Start Admin */}
                          <div className="col-md-2"></div>
                          <div
                            className={`col-md-10 admin ${
                              type === "mobile" && "ms-sm-4"
                            }  mb-3 "`}
                            key={index}
                          >
                            <div className="d-flex justify-content-end">
                              {" "}
                              <div>
                                <div className="d-flex justify-content-end">
                                  <span className="sender">
                                    {item?.senderName}
                                  </span>
                                  &nbsp;&nbsp;&nbsp;
                                  <span className="sender_time">
                                    {formatTime(item?.receievedAtTimestamp)}
                                  </span>
                                </div>
                                <div className="mt-1 msg_cont_sender p-2">
                                  {item?.message}
                                </div>
                              </div>
                              <img
                                src={avatar}
                                alt="avatar"
                                height={20}
                                className=" ms-2 mt-4"
                              />{" "}
                            </div>
                          </div>{" "}
                          {/* End Admin */}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
      <div className="position-relative">
        {" "}
        {base64Attachment && (
          <div
            className="mt-3 position-absolute "
            style={{ bottom: "5px", left: "15px" }}
          >
            <img height="50" src={base64Attachment} alt="" />
          </div>
        )}
      </div>
      <div className="chatbtm footer d-flex align-items-center">
        {type === "mobile" ? (
          <input
            className="form-control me-2 ms-3"
            style={{ width: "270px" }}
            placeholder="Write message here"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addComment(e);
              }
            }}
          />
        ) : (
          <input
            className="form-control me-2 ms-3"
            placeholder="Write message here"
            style={{ width: "280px" }}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addComment(e);
              }
            }}
          />
        )}
        <input type="file" id="file" hidden onChange={uploadImage} />
        <label htmlFor="file">
          <img src={add} alt="add" width={25} className="me-2 cursor-pointer" />
        </label>

        <img
          src={send}
          alt="send"
          width={25}
          className=" cursor-pointer"
          onClick={addComment}
        />
      </div>
    </div>
  );
};

export default Appeal;
