import React from "react";
import { Modal, Button } from "react-bootstrap";
import logo from "../../images/Success.svg";

const SignupSuccessModal = ({ modal, setModalType }) => {
  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-5 justify-content-center text-center">
          <div>
            <img src={logo} alt="logo" height={150} />
            <h3 className="py-3">Registration Successful</h3>

            <div className="d-grid gap-2 mt-5 mb-3">
              <Button
                variant="primary"
                className=" btn-style mt-4"
                size="md"
                onClick={() => setModalType("Login")}
              >
                Proceed to Login
              </Button>
              <a
                className="btn  py-2 close"
                href="/#"
                onClick={() => setModalType()}
              >
                Close
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignupSuccessModal;
