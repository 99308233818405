import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import logo from "../../images/logo2.png";

const ForgotPasswordModal = ({ modal, setModalType, setEmailOrPhone }) => {
  // Start Context
  const { generateOTPForPassword } = useContext(AuthContext);
  // End Context

  const [EmailOrPhone, setEmail] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState();

  const submitHandler = async () => {
    if (!EmailOrPhone) {
      toast.error("Please enter your email address");
    } else {
      setIsButtonLoading(true);
      setEmailOrPhone(EmailOrPhone);

      let payload = {
        emailAddress: EmailOrPhone,
      };

      try {
        let response = await generateOTPForPassword(payload);
        if (response?.data) {
          setModalType("ResetPasswordModal");
        }else {
          toast.error(response?.Message || "Oops, Something went wrong");
        }
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }

      setIsButtonLoading(false);
    }
  };

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-4 px-4 justify-content-center text-center">
          <div>
            <img src={logo} alt="logo" height={40} />
            <h3 className="py-3">Forgot Password</h3>
            <p>Enter your email, and we'll send you a verification pin</p>
            <div className="form-group mb-4 mt-5">
              <input
                type="text"
                className="form-control"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="d-grid gap-2 mt-4 mb-3">
              {isButtonLoading ? (
                <Button
                  disabled
                  variant="primary "
                  className="py-2 btn-style"
                  size="md"
                >
                  <i
                    className="fa fa-spinner fa-pulse mx-2 "
                    aria-hidden="true"
                  ></i>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="py-2 btn-style"
                  size="md"
                  onClick={() => submitHandler()}
                >
                  Submit
                </Button>
              )}
            </div>
            <a
              className="btn  py-2 close"
              href="/#"
              onClick={() => setModalType()}
            >
              Close
            </a>

            <div className="terms mt-3 ">
              Already have an account?{" "}
              <a
                href="/#"
                className=" close ms-1"
                onClick={() => setModalType("Login")}
              >
                Login
              </a>{" "}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
