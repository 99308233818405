import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import logo from "../../images/logo2.png";

const ResetPasswordModal = ({ modal, setModalType, emailOrPhone }) => {
  // Start Context
  const { validateOTPForPassword } = useContext(AuthContext);
  // End Context

  const [otp, setOtp] = useState();

  const [password, setPassword] = useState();
  const [cfmPassword, setCfmPassword] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState();

  let validatePassword = password?.match(
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/
  );
  const submitHandler = async () => {
    if (!otp || !password) {
      toast.error("Input fields cannot be blank");
    } else if (!validatePassword) {
      toast.error(
        "Password must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      );
    } else if (password !== cfmPassword) {
      toast.error("Password does not match");
    } else {
      setIsButtonLoading(true);

      try {
        let payload = {
          emailAddress: emailOrPhone,
          newPassword: password,
          otp,
        };
        let response = await validateOTPForPassword(payload);
        if (response?.data) {
          setModalType("ResetPasswordSuccess");
        } else {
          toast.error(response?.message || "Oops, Something went wrong");
        }
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
      setIsButtonLoading(false);
    }
  };

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-4 px-4 justify-content-center ">
          <div>
            <div className="text-center">
              <img src={logo} alt="logo" height={40} />
              <h3 className="py-3">Reset Password</h3>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12 ">
                {!validatePassword && (
                  <div
                    class="d-flex align-items-center alert py-1 alert-danger mb-3"
                    role="alert"
                  >
                    <i
                      class="fa fa-2x fa-exclamation-circle text-red me-2"
                      aria-hidden="true"
                    ></i>{" "}
                    <small className="font-size-12">
                      Password must Contain 6 Characters, One Uppercase, One
                      Lowercase, One Number and One Special Case Character
                    </small>
                  </div>
                )}
                <input
                  type="password"
                  className="form-control mb-3"
                  placeholder="Password "
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="col-lg-12 mb-3 ">
                <input
                  type="password"
                  className="form-control "
                  placeholder="Confirm Password "
                  onChange={(e) => setCfmPassword(e.target.value)}
                />
                {validatePassword && password !== cfmPassword && (
                  <small className="text-danger">Password does not match</small>
                )}
              </div>
              <div className="col-lg-12 ">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="OTP "
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
            </div>

            <div className="d-grid gap-2 mt-4 mb-3 text-center">
              {isButtonLoading ? (
                <Button
                  disabled
                  variant="primary "
                  className="py-2 btn-style"
                  size="md"
                >
                  <i
                    className="fa fa-spinner fa-pulse mx-2 "
                    aria-hidden="true"
                  ></i>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="py-2 btn-style"
                  size="md"
                  onClick={() => submitHandler()}
                >
                  Submit
                </Button>
              )}
            </div>
            <div className="text-center">
              <a
                className="btn  py-2 close "
                href="/#"
                onClick={() => setModalType()}
              >
                Close
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResetPasswordModal;
