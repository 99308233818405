import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import logo from "../../images/logo2.png";
const google = (window.google = window.google ? window.google : {});

const SignupModal = ({ modal, setModalType, setEmailOrPhone }) => {
  // Start Context
  const { generateOTPForEmail, googleAuth } = useContext(AuthContext);
  // End Context

  const [EmailOrPhone, setEmail] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState();

  const submitHandler = async () => {
    if (!EmailOrPhone) {
      toast.error("Please enter your email address");
    } else {
      setIsButtonLoading(true);
      setEmailOrPhone(EmailOrPhone);

      let payload = {
        emailOrPhone: EmailOrPhone,
      };

      try {
        let response = await generateOTPForEmail(payload);
        if (response?.message) {
          setModalType("VerifySignup");
        } else {
          toast.error(response?.Message || "Oops, Something went wrong");
        }
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }

      setIsButtonLoading(false);
    }
  };

  const handleCredentialResponse = async (response) => {
    let payload = {
      authProvider: "Google",
      idToken: response?.credential,
    };
    let resp = await googleAuth(payload);
    if (resp?.data) {
      setModalType("SignupSuccess");
    }
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "940228248038-n2qismbiq1vvvvvf2levgs6p323gqa79.apps.googleusercontent.com",
      callback: (data) => handleCredentialResponse(data),
    });

    // Display the One Tap prompt
    google.accounts.id.prompt();

    google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
      theme: "outline",
      size: "large",
      text: "signup_with",
    });
  }, []);

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-4 px-4 justify-content-center text-center">
          <div>
            <img src={logo} alt="logo" height={40} />
            <h3 className="py-3">Sign Up</h3>
            <p>Enter your email, and we'll send you a verification Pin</p>
            <div className="form-group mb-4 mt-5">
              <input
                type="text"
                className="form-control"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="terms ">
              By Signing up on XendBridge, you agree to the{" "}
              <a href="/#" style={{ color: "#797979" }}>
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="/#" style={{ color: "#797979" }}>
                Privacy Policy
              </a>
              .
            </div>
            <div className="d-grid gap-2 mt-4 mb-3">
              {isButtonLoading ? (
                <Button
                  disabled
                  variant="primary "
                  className="py-2 btn-style"
                  size="md"
                >
                  <i
                    className="fa fa-spinner fa-pulse mx-2 "
                    aria-hidden="true"
                  ></i>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="py-2 btn-style"
                  size="md"
                  onClick={() => submitHandler()}
                >
                  Agree & Continue
                </Button>
              )}
            </div>
            <h5 className="text-center">OR</h5>
            <div className="mt-3 mb-4 d-flex justify-content-center">
              <div id="signUpDiv"></div>
            </div>
            <a
              className="btn  py-2 close"
              href="/#"
              onClick={() => setModalType()}
            >
              Close
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignupModal;
