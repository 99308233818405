import React, { useEffect, useState } from 'react';
import { FormControl, InputGroup, Dropdown } from 'react-bootstrap';
import { cryptoNetworks } from '../../utils/cryptoNetworks';

const Crypto = ({
  cryptoCurrencyImg,
  setCryptoCurrencyImg,
  setCryptoCurrency,
  setCryptoNetwork,
  cryptoCurrencies,
  cryptoCurrency,
  setMinAmountErrorMessage,
  setMaxAmountErrorMessage,
  maxAmount,
  handleOrderRate,
  cryptoValue,
  setCryptoValue,
  setCryptoNetworkValue,
  cryptoNetworkValue,
  setCryptoNetworkImg,
  cryptoNetworkImg,
}) => {
  const [networkCryptoCurrencies, setNetworkCryptoCurrencies] = useState();

  useEffect(() => {
    let networkCryptoCurrencies_ = cryptoCurrencies.filter(
      (item) => item.network === cryptoNetworkValue
    );
    
    // Extract the currency with "USDT"
    const usdtIndex = networkCryptoCurrencies_.findIndex(
      (item) => item.currencySymbol === "USDT"
    );

    // Move the "USDT" currency to the beginning of the array
    if (usdtIndex !== -1) {
      const usdtItem = networkCryptoCurrencies_.splice(usdtIndex, 1)[0];
      networkCryptoCurrencies_.unshift(usdtItem);
    }

    setNetworkCryptoCurrencies(networkCryptoCurrencies_);
    setCryptoCurrency(networkCryptoCurrencies_[0]?.currencySymbol);
    setCryptoCurrencyImg(networkCryptoCurrencies_[0]?.symbolUrl);
  }, [
    cryptoCurrencies,
    cryptoNetworkValue,
    setCryptoCurrency,
    setCryptoCurrencyImg,
  ]);

  return (
    <>
      {' '}
      <InputGroup className="mb-2">
        <FormControl
          placeholder="Enter crypto amount"
          className="input-width-2"
          value={cryptoValue}
          onChange={(e) => {
            handleOrderRate('CRYPTO', e.target.value);
            if (e.target.value < 1) {
              setMinAmountErrorMessage(true);
            } else if (e.target.value > maxAmount) {
              setMaxAmountErrorMessage(true);
            } else {
              setMinAmountErrorMessage(false);
              setMaxAmountErrorMessage(false);
            }
          }}
        />

        <Dropdown>
          <Dropdown.Toggle
            variant=""
            as="div"
            className="form-control style-1 default-select text-dark-black d-flex align-items-center  p-2 pe-4"
            style={{ fontSize: '12px' }}
          >
            {networkCryptoCurrencies?.length < 1 ? (
              <> &nbsp;&nbsp; &nbsp;&nbsp; ------</>
            ) : (
              <>
                {' '}
                {cryptoCurrencyImg && (
                  <img
                    src={cryptoCurrencyImg}
                    alt="currencyImage"
                    height={13}
                  />
                )}
                &nbsp;&nbsp;
                {cryptoCurrency}
              </>
            )}
          </Dropdown.Toggle>{' '}
          <Dropdown.Menu>
            {networkCryptoCurrencies?.map((item, i) => (
              <Dropdown.Item
                style={{ fontSize: '12px', padding: ' 0.7rem 1.2rem' }}
                onClick={() => {
                  setCryptoValue('');
                  setCryptoCurrency(item?.currencySymbol);
                  setCryptoCurrencyImg(item?.symbolUrl);
                  setCryptoNetwork(item?.network);
                }}
                key={i}
              >
                {item?.symbolUrl && (
                  <>
                    <img
                      src={item?.symbolUrl}
                      alt="currencyImage"
                      height={13}
                    />
                    &nbsp;&nbsp;
                  </>
                )}
                <strong>{item?.currencySymbol}</strong>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {item?.name}&nbsp;&nbsp;({item?.network})
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    </>
  );
};

export default Crypto;
