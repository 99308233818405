import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import copyIcon from "../images/copy.svg";
import Appeal from "./Appeal";
import chat from "../images/Outline.svg";
import { GlobalContext } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { formatCurrency } from "../utils/fx";
import Countdown from "react-countdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SkeletonLoader from "./SkeletonLoader";
import arrow from "../images/arrow.svg";

const BuyCryptoPendingDetails = ({
  setModalType,
  setDisputeStatus,
  setOrderReference,
  setDisputeReference,
}) => {
  let navigate = useNavigate();
  // Start Context
  const {
    bestRate,
    pendingOrder,
    isPageLoading,
    getPendingOrder,
    confirmOrder,
    cancelOrder,
  } = useContext(GlobalContext);
  // End Context

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCancelButtonLoading, setIsCancelButtonLoading] = useState(false);

  useEffect(() => {
    getPendingOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = async () => {
    setIsButtonLoading(true);
    try {
      let payload = {
        emailAddress: pendingOrder?.orderResponse?.customerContact?.email,
        orderReference: pendingOrder?.orderResponse?.orderReference,
        transactionHash: "",
      };
      let response = await confirmOrder(payload);

      if (response?.data) {
        navigate(
          `/view-order-request/${pendingOrder?.orderResponse?.orderReference}`
        );
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || "Oops, Something went wrong");
    }
    setIsButtonLoading(false);
  };

  const cancelHandler = async () => {
    setIsCancelButtonLoading(true);
    try {
      let payload = {
        orderReference: pendingOrder?.orderResponse?.orderReference,
      };
      let response = await cancelOrder(payload);

      if (response?.data) {
        navigate(`/order-requests`);
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || "Oops, Something went wrong");
    }
    setIsCancelButtonLoading(false);
  };

  const copyText = () => {
    toast.success("Copied successfully!");
  };

  return (
    <div className="margin-top-xs  ">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              {pendingOrder?.orderResponse?.receiveInCurrencyCode && (
                <p className="receiving-details title ps-1">
                  <a href="/order-requests" className="me-4">
                    <img src={arrow} alt="previous arrow" />
                  </a>
                  Buy {pendingOrder?.orderResponse?.receiveInCurrencyCode}
                </p>
              )}
              <div className="order-card-lg p-4">
                <div className="row">
                  <div className="col-md-6 buycrypto">
                    {" "}
                    {isPageLoading ? (
                      <SkeletonLoader />
                    ) : (
                      <>
                        <div className="row ">
                          <div className="col-md-6">
                            <h6>When you pay</h6>
                            <p className="mb-1">
                              {formatCurrency(
                                pendingOrder?.orderResponse?.payableAmount
                              )}{" "}
                              <span>
                                {pendingOrder?.orderResponse?.payInCurrencyCode}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <h6>You will receive</h6>
                            <p className="dark mb-1">
                              {formatCurrency(
                                pendingOrder?.orderResponse?.receivableAmount
                              )}{" "}
                              <span>
                                {
                                  pendingOrder?.orderResponse
                                    ?.receiveInCurrencyCode
                                }
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                        {bestRate && (
                          <span className="rate">
                            1{" "}
                            {pendingOrder?.orderResponse?.receiveInCurrencyCode}{" "}
                            ~ {bestRate}{" "}
                            {pendingOrder?.orderResponse?.payInCurrencyCode}
                          </span>
                        )}
                        <div className="bank-details p-3 mt-3">
                          <p className="title">
                            Send Money to the Merchant's Account details
                            provided below.
                          </p>
                          <div className="form-group">
                            <select className="form-control" disabled>
                              <option>Select payment method</option>
                              <option selected value="">
                                {
                                  pendingOrder?.orderResponse
                                    ?.providerPaymentMethods?.paymentMethod
                                }
                              </option>
                            </select>
                          </div>

                          <div className="row mt-4">
                            {pendingOrder?.orderResponse?.providerPaymentMethods
                              ?.orderPaymentMethod?.bankName && (
                              <div className="col-md-4">
                                <p className="head mb-0">Bank</p>
                                <p className="subhead mb-1">
                                  {
                                    pendingOrder?.orderResponse
                                      ?.providerPaymentMethods
                                      ?.orderPaymentMethod?.bankName
                                  }
                                </p>
                              </div>
                            )}
                            <div className="col-md-8">
                              <p className="head mb-0">Account Name</p>
                              <div className="d-flex mb-1">
                                <p className="subhead mb-0 me-1">
                                  {
                                    pendingOrder?.orderResponse
                                      ?.providerPaymentMethods
                                      ?.orderPaymentMethod?.accountName
                                  }
                                </p>{" "}
                                {pendingOrder?.orderResponse
                                  ?.providerPaymentMethods?.orderPaymentMethod
                                  ?.accountName && (
                                  <CopyToClipboard
                                    text={
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.accountName
                                    }
                                    onCopy={() => copyText()}
                                  >
                                    <img
                                      src={copyIcon}
                                      alt="copyIcon"
                                      className="cursor-pointer"
                                    />
                                  </CopyToClipboard>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {pendingOrder?.orderResponse?.providerPaymentMethods
                              ?.orderPaymentMethod?.accountNumber && (
                              <div className="col-md-4">
                                <p className="head mb-0">Account Number</p>
                                <div className="d-flex mb-1">
                                  <p className="subhead mb-0 me-1">
                                    {
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.accountNumber
                                    }
                                  </p>{" "}
                                  {pendingOrder?.orderResponse
                                    ?.providerPaymentMethods?.orderPaymentMethod
                                    ?.accountNumber && (
                                    <CopyToClipboard
                                      text={
                                        pendingOrder?.orderResponse
                                          ?.providerPaymentMethods
                                          ?.orderPaymentMethod?.accountNumber
                                      }
                                      onCopy={() => copyText()}
                                    >
                                      <img
                                        src={copyIcon}
                                        alt="copyIcon"
                                        className="cursor-pointer"
                                      />
                                    </CopyToClipboard>
                                  )}
                                </div>
                              </div>
                            )}

                            {pendingOrder?.orderResponse?.providerPaymentMethods
                              ?.orderPaymentMethod?.accountId && (
                              <div className="col-md-4">
                                <p className="head mb-0">Account ID</p>
                                <div className="d-flex mb-1">
                                  <p className="subhead mb-0 me-1">
                                    {
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.accountId
                                    }{" "}
                                  </p>{" "}
                                  <CopyToClipboard
                                    text={
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.accountId
                                    }
                                    onCopy={() => copyText()}
                                  >
                                    <img
                                      src={copyIcon}
                                      alt="copyIcon"
                                      className="cursor-pointer"
                                    />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            )}

                            {pendingOrder?.orderResponse?.providerPaymentMethods
                              ?.orderPaymentMethod?.phoneNumber && (
                              <div className="col-md-4">
                                <p className="head mb-0">Phone No.</p>

                                <div className="d-flex align-items-center">
                                  <p className="subhead mb-0 me-1">
                                    {
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.phoneNumber
                                    }
                                  </p>
                                  <CopyToClipboard
                                    text={
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.phoneNumber
                                    }
                                    onCopy={() => copyText()}
                                  >
                                    <img
                                      src={copyIcon}
                                      alt="copyIcon"
                                      className="cursor-pointer"
                                    />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            )}

                            <div className="col-md-8">
                              <p className="head mb-0">Reference</p>
                              <div className="d-flex mb-1">
                                <p className="subhead mb-0 me-1">
                                  {pendingOrder?.orderResponse?.orderReference}
                                </p>{" "}
                                {pendingOrder?.orderResponse
                                  ?.orderReference && (
                                  <CopyToClipboard
                                    text={
                                      pendingOrder?.orderResponse
                                        ?.orderReference
                                    }
                                    onCopy={() => copyText()}
                                  >
                                    <img
                                      src={copyIcon}
                                      alt="copyIcon"
                                      className="cursor-pointer"
                                    />
                                  </CopyToClipboard>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="timing mt-4">
                          <p className="mb-0">
                            {" "}
                            Time remaining:
                            <Countdown
                              date={
                                pendingOrder?.orderResponse
                                  ?.orderExpiryTimestamp
                              }
                              renderer={({ minutes, seconds }) => (
                                <span className="text-danger">
                                  {" "}
                                  {minutes || "00"}:{seconds || "00"}
                                </span>
                              )}
                            />
                          </p>
                          <p className="title">
                            Please make a payment within 15:00 mins, otherwise,
                            the order will be cancelled
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {" "}
                            {isButtonLoading ? (
                              <Button
                                disabled
                                variant="primary "
                                className="py-2 btn-style"
                                size="md"
                              >
                                <i
                                  className="fa fa-spinner fa-pulse mx-5"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            ) : (
                              <button
                                className="btn btn-primary py-2 btn-style me-3"
                                size="md"
                                onClick={() => submitHandler()}
                              >
                                I have made transfer
                              </button>
                            )}
                            {isCancelButtonLoading ? (
                              <Button
                                disabled
                                variant="danger "
                                className="py-2 btn-style"
                                size="md"
                              >
                                <i
                                  className="fa fa-spinner fa-pulse mx-5"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            ) : (
                              <button
                                className="btn btn-danger py-2 btn-style"
                                size="md"
                                onClick={() => cancelHandler()}
                              >
                                Cancel order
                              </button>
                            )}
                          </div>
                          <img
                            src={chat}
                            alt="chat"
                            className="me-1 cursor-pointer d-appeal"
                            onClick={() => {
                              setDisputeStatus(
                                pendingOrder?.orderResponse?.disputeStatus
                              );
                              setOrderReference(
                                pendingOrder?.orderResponse?.orderReference
                              );
                              setDisputeReference(
                                pendingOrder?.orderResponse?.disputeReference
                              );
                              setModalType("AppealModal");
                            }}
                          />
                        </div>{" "}
                      </>
                    )}
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-5 hide-appeal">
                    <Appeal
                      disputeStatus={pendingOrder?.orderResponse?.disputeStatus}
                      orderReference={
                        pendingOrder?.orderResponse?.orderReference
                      }
                      disputeReference={
                        pendingOrder?.orderResponse?.disputeReference
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={false} />
    </div>
  );
};

export default BuyCryptoPendingDetails;
