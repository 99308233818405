import { Navigate } from "react-router-dom";
import { getItemFromSessionStorage } from "./fx";

export const ProtectedRoute = ({ children }) => {
  let accessToken = getItemFromSessionStorage("accessToken");

  let tokenExpires = getItemFromSessionStorage("tokenExpires");
  let expirationTime = new Date(tokenExpires).getTime() / 1000 - 1000;
  let currentTime = Math.floor(new Date().getTime() / 1000.0);

  if (expirationTime && currentTime >= expirationTime) {
    sessionStorage.clear();
    // window.location.reload();
    return <Navigate to="/" replace />;
  } else if (!accessToken) {
    // window.location.reload();
    return <Navigate to="/" replace />;
  }

  return children;
};
