import React, { createContext } from "react";
import {
  createGenerateOTP,
  createValidateOTP,
  createCustomer,
  createLogin,
  createGenerateOTPPassword,
  createValidateOTPPassword,
  createGoogleAuth,
} from "../apis/Create.js";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  //  GENERATE OTP FOR USER EMAIL ADDRESS
  const generateOTPForEmail = async (payload) => {
    return await createGenerateOTP(payload);
  };

  //  VALIDATE USER EMAIL ADDRESS
  const validateOTPForEmail = async (payload) => {
    return await createValidateOTP(payload);
  };

  //  GENERATE OTP TO RESET PASSWORD
  const generateOTPForPassword = async (payload) => {
    return await createGenerateOTPPassword(payload);
  };

  //  VALIDATE OTP TO RESET PASSWORD
  const validateOTPForPassword = async (payload) => {
    return await createValidateOTPPassword(payload);
  };

  //  CUSTOMER SIGNUP
  const registerCustomer = async (payload) => {
    return await createCustomer(payload);
  };

  //  CUSTOMER LOGIN
  const loginCustomer = async (payload) => {
    return await createLogin(payload);
  };

  //  GOGLE AUTH
  const googleAuth = async (payload) => {
    return await createGoogleAuth(payload);
  };

  //  LOGOUT
  const logoutCustomer = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        generateOTPForEmail,
        validateOTPForEmail,
        registerCustomer,
        loginCustomer,
        logoutCustomer,
        generateOTPForPassword,
        validateOTPForPassword,
        googleAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
