import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import Appeal from "./Appeal";
import mobilechat from "../images/mobilechat.svg";
import completed from "../images/completed.svg";
import cancelled from "../images/cross.png";
import { GlobalContext } from "../contexts/GlobalContext";
import { formatCurrency } from "../utils/fx";
import SkeletonLoader from "./SkeletonLoader";
import arrow from "../images/arrow.svg";

const OrderSuccessPage = ({
  setModalType,
  setDisputeStatus,
  setOrderReference,
  setDisputeReference,
}) => {
  // Start Context
  const { getOrderRequest } = useContext(GlobalContext);
  // End Context

  const orderReference = window.location.pathname.split("/")[2];

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    let res = await getOrderRequest(orderReference);
    setData(res?.data);
    res?.data && setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data, "datadatadata");

  return (
    <div className="margin-top-xs  ">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              <p className="receiving-details title ps-1">
                <a href="/order-requests" className="me-4">
                  <img src={arrow} alt="previous arrow" />
                </a>
                {data?.orderDetail?.orderType === "Buy" &&
                  `Buy ${data?.orderDetail?.receiveInCurrencyCode}`}
                {data?.orderDetail?.orderType === "Sell" &&
                  `Sell ${data?.orderDetail?.payInCurrencyCode}`}
                {data?.orderDetail?.orderType === "Swap" &&
                  `Swap ${data?.orderDetail?.payInCurrencyCode}`}
              </p>

              <div className="order-card-lg p-4">
                <div className="row">
                  <div className="col-md-6 buycrypto p-3">
                    {" "}
                    {loading ? (
                      <SkeletonLoader />
                    ) : (
                      <>
                        <div className="text-center">
                          {data?.orderDetail?.orderStatus === "Cancelled" ||
                          data?.orderDetail?.orderStatus === "OrderTimedOut" ? (
                            <img src={cancelled} height={79} alt="cancelled" />
                          ) : (
                            <img src={completed} alt="completed" />
                          )}

                          {data?.orderDetail?.orderType === "Buy" ? (
                            <>
                              {" "}
                              <h6 className="mt-3 mb-2">
                                {data?.orderDetail?.orderStatus ===
                                  "Completed" && "Buy Order Completed"}
                                {data?.orderDetail?.orderStatus ===
                                  "Cancelled" && "Buy Order Canceled"}
                                {data?.orderDetail?.orderStatus ===
                                  "OrderTimedOut" && "Buy Order Expired"}
                                {data?.orderDetail?.orderStatus ===
                                  "FundReceivedFromUser" && "Payment Confirmed"}
                                {data?.orderDetail?.orderStatus ===
                                  "FundedProvider" &&
                                  "Awaiting Payment Confirmation"}
                              </h6>
                              {(data?.orderDetail?.orderStatus ===
                                "FundReceivedFromUser" ||
                                data?.orderDetail?.orderStatus ===
                                  "FundedProvider") && (
                                <>
                                  {" "}
                                  <p className="mb-2">
                                    {data?.orderDetail?.payableAmount}
                                    <span>
                                      &nbsp;
                                      {data?.orderDetail?.payInCurrencyCode}
                                    </span>
                                  </p>
                                </>
                              )}
                              {(data?.orderDetail?.orderStatus ===
                                "Completed" ||
                                data?.orderDetail?.orderStatus ===
                                  "Cancelled" ||
                                data?.orderDetail?.orderStatus ===
                                  "OrderTimedOut") && (
                                <p className="mb-2">
                                  {data?.orderDetail?.receivableAmount}
                                  <span>
                                    &nbsp;
                                    {data?.orderDetail?.receiveInCurrencyCode}
                                  </span>
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <h6 className="mt-3 mb-2">
                                {data?.orderDetail?.orderStatus ===
                                  "Completed" &&
                                  `${data?.orderDetail?.orderType} Order Completed`}
                                {data?.orderDetail?.orderStatus ===
                                  "Cancelled" &&
                                  `${data?.orderDetail?.orderType} Order Cancelled`}
                                {data?.orderDetail?.orderStatus ===
                                  "OrderTimedOut" &&
                                  `${data?.orderDetail?.orderType} Order CancelExpiredled`}
                                {data?.orderDetail?.orderStatus ===
                                  "FundReceivedFromUser" && "Payment Confirmed"}
                                {data?.orderDetail?.orderStatus ===
                                  "FundedProvider" &&
                                  "Awaiting Payment Confirmation"}
                              </h6>
                              {(data?.orderDetail?.orderStatus ===
                                "FundReceivedFromUser" ||
                                data?.orderDetail?.orderStatus ===
                                  "FundedProvider") && (
                                <>
                                  {" "}
                                  <p className="mb-2">
                                    {data?.orderDetail?.payableAmount}
                                    <span>
                                      &nbsp;
                                      {data?.orderDetail?.payInCurrencyCode}
                                    </span>
                                  </p>
                                </>
                              )}
                              {(data?.orderDetail?.orderStatus ===
                                "Completed" ||
                                data?.orderDetail?.orderStatus ===
                                  "Cancelled" ||
                                data?.orderDetail?.orderStatus ===
                                  "OrderTimedOut") && (
                                <p className="mb-2">
                                  {data?.orderDetail?.receivableAmount}
                                  <span>
                                    &nbsp;
                                    {data?.orderDetail?.receiveInCurrencyCode}
                                  </span>
                                </p>
                              )}
                            </>
                          )}

                          <div className="timing  d-flex justify-content-center mb-3 ">
                            <p className="title " style={{ width: "280px" }}>
                              {data?.orderDetail?.orderStatus ===
                                "FundedProvider" &&
                                data?.orderDetail?.receiveInCurrencyNetwork !==
                                  "LOCAL" && (
                                  <>
                                    {" "}
                                    We will confirm the receipts of fund, and
                                    send crypto to your wallet within the next
                                    30 minutes.{" "}
                                  </>
                                )}
                              {data?.orderDetail?.orderStatus ===
                                "FundReceivedFromUser" &&
                                data?.orderDetail?.receiveInCurrencyNetwork !==
                                  "LOCAL" && (
                                  <>
                                    {" "}
                                    We have received your receipts of fund, and
                                    we will send crypto to your wallet within
                                    the next 30 minutes.{" "}
                                  </>
                                )}

                              {data?.orderDetail?.orderStatus ===
                                "FundedProvider" &&
                                data?.orderDetail?.receiveInCurrencyNetwork ===
                                  "LOCAL" && (
                                  <>
                                    We will confirm your deposit, and send your
                                    fiat to your account within the next 30
                                    minutes
                                  </>
                                )}
                              {data?.orderDetail?.orderStatus ===
                                "FundReceivedFromUser" &&
                                data?.orderDetail?.receiveInCurrencyNetwork ===
                                  "LOCAL" && (
                                  <>
                                    {" "}
                                    We have received your deposit, and we will
                                    send fiat to your account within the next 30
                                    minutes
                                  </>
                                )}
                            </p>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12">
                            <span className="trans-head">
                              Transaction Details
                            </span>
                            <div className="trans-details d-flex mt-2 justify-content-between align-items-center">
                              <h6>Payment Method</h6>
                              <p className="mb-1">
                                {
                                  data?.liquidityProviderPaymentOption
                                    ?.paymentMethod
                                }
                              </p>
                            </div>
                            <div className="trans-details d-flex justify-content-between align-items-center">
                              <h6>Amount Paid</h6>
                              <p className="mb-1">
                                {formatCurrency(
                                  data?.orderDetail?.payableAmount
                                )}{" "}
                                {data?.orderDetail?.payInCurrencyCode}{" "}
                                {data?.orderDetail?.payInCurrencyNetwork !==
                                  "LOCAL" && (
                                  <>
                                    ({data?.orderDetail?.payInCurrencyNetwork})
                                  </>
                                )}
                              </p>
                            </div>
                            {data?.orderDetail?.receivableAmount !== 0 && (
                              <>
                                <div className="trans-details d-flex justify-content-between align-items-center">
                                  <h6>Amount to Receive</h6>
                                  <p className="mb-1">
                                    {formatCurrency(
                                      data?.orderDetail?.receivableAmount
                                    )}{" "}
                                    {data?.orderDetail?.receiveInCurrencyCode}{" "}
                                    {data?.orderDetail
                                      ?.receiveInCurrencyNetwork !==
                                      "LOCAL" && (
                                      <>
                                        (
                                        {
                                          data?.orderDetail
                                            ?.receiveInCurrencyNetwork
                                        }
                                        )
                                      </>
                                    )}
                                  </p>
                                </div>
                              </>
                            )}

                            <div className=" trans-details d-flex justify-content-between align-items-center">
                              <h6>Reference</h6>
                              <p className="subhead mb-0">
                                {data?.orderDetail?.orderReference}
                              </p>{" "}
                            </div>

                            <div className=" trans-details d-flex justify-content-between align-items-center">
                              <h6>Date</h6>
                              <p className="mb-1">
                                {new Date(
                                  data?.orderDetail?.initiatedAtTimestamp
                                ).toLocaleDateString()}
                                &nbsp;
                                {new Intl.DateTimeFormat("default", {
                                  hour12: true,
                                  hour: "numeric",
                                  minute: "numeric",
                                }).format(
                                  data?.orderDetail?.initiatedAtTimestamp
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end  align-items-center">
                          <img
                            src={mobilechat}
                            alt="chat"
                            className="cursor-pointer d-appeal"
                            onClick={() => {
                              setDisputeStatus(
                                data?.orderDetail?.disputeStatus
                              );
                              setOrderReference(
                                data?.orderDetail?.orderReference
                              );
                              setDisputeReference(
                                data?.orderDetail?.disputeReference
                              );
                              setModalType("AppealModal");
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-5 hide-appeal">
                    <Appeal
                      orderReference={data?.orderDetail?.orderReference}
                      disputeStatus={data?.orderDetail?.disputeStatus}
                      disputeReference={data?.orderDetail?.disputeReference}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={false} />
    </div>
  );
};

export default OrderSuccessPage;
