import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import PageLoader from "../components/PageLoader";
import noDataImg from "../images/no_data.gif";
import OrderRequestsTable from "../components/OrderRequestsTable";
import Pagination from "../components/Pagination";
import Filters from "../components/Filters";

let PageSize = 10;

const Orderhistory = () => {
  const {
    getOrderRequests,
    allOrderRequests,
    isPageLoading,
    getNewOrderRequests,
  } = useContext(GlobalContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [getHistoryAcrossApps, setGetHistoryAcrossApps] = useState(true);

  const handleDataChange = async (page) => {
    setCurrentPage(page);
    // const filtered =
    await getOrderRequests(page, getHistoryAcrossApps);
    // setFilteredData(filtered.data.records);
  };

  const handleDataFilter = async (getHistoryAcrossAppsBoolean) => {
    setGetHistoryAcrossApps(getHistoryAcrossAppsBoolean);
    await getOrderRequests(currentPage, getHistoryAcrossAppsBoolean);
  };

  useEffect(() => {
    if (currentPage === 1) {
      const intervalCall = setInterval(() => {
        getNewOrderRequests(1, getHistoryAcrossApps);
      }, 5000); //5 seconds
      return () => {
        clearInterval(intervalCall);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, getHistoryAcrossApps]);

  return (
    <div className="margin-top">
      <div className="container">
        {isPageLoading ? (
          <PageLoader />
        ) : (
          <div className="row ">
            <div className="col-md-12 mb-0">
              <span className="font-w600 mb-0 h3 text-dark-black">
                Order Requests
              </span>
            </div>{" "}
            <div className="col-md-12">
              <Filters
                getHistoryAcrossApps={getHistoryAcrossApps}
                handleDataFilter={handleDataFilter}
              />
            </div>
            <div className="col-md-12 ">
              <div
                id="example5_wrapper"
                className="dataTables_wrapper no-footer"
              >
                {allOrderRequests?.records < 1 ? (
                  <>
                    <div className="d-flex justify-content-center align-items-center vh-80">
                      <div>
                        <span className="imgbox d-flex justify-content-center">
                          <img
                            src={noDataImg}
                            height="230px"
                            alt="emptyimage"
                            className=" emptyimage"
                          />
                        </span>
                        <p className="text-center text-blue fs-18 mt-3">
                          Order Requests is empty!
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="table-responsive table-hover fs-14">
                          <div
                            id="example5_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            {allOrderRequests?.records && (
                              <>
                                <OrderRequestsTable
                                  currentTableData={allOrderRequests?.records}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Pagination
                        className="pagination-bar mb-5"
                        currentPage={currentPage}
                        totalCount={allOrderRequests?.totalRecords}
                        pageSize={PageSize}
                        onPageChange={(page) => handleDataChange(page)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Orderhistory;
