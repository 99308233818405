export const cryptoNetworks = [
  {
    name: 'BEP20',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1706618678/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com_bxyvsw.png',
  },
  {
    name: 'ERC20',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1668785496/untitled_zzns3h.png',
  },
  {
    name: 'POLYGON',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1668773793/polygon_detdu6.png',
  },
  // {
  //   name: 'TRON',
  //   symbolUrl:
  //     'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1670234612/download-removebg-preview_pmpcrj.png',
  // },
  {
    name: 'ARBITRUM',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1706618512/arbitrum-arb-logo_l0xk7y.png',
  },
];
