import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { GlobalContext } from "../../../contexts/GlobalContext";
import arrow from "../../../images/arrow.svg";
import Footer from "../../Footer";

const ReceiveCryptoPaymentDetailsForm = ({
  setActivePage,
  setReceiveWalletAddress,
  receiveWalletAddress,
  setIsActive,
}) => {
  // Start Context
  const { receiveInCurrency, receiveInNetwork } =
    useContext(GlobalContext);
  // End Context

  return (
    <div className="margin-top-sm receiving-details">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              <p className="title ">Payment details</p>

              <div className="order-card p-4">
                <img
                  src={arrow}
                  alt="previous arrow"
                  className="cursor-pointer"
                  onClick={() => {
                    setActivePage("payCryptoPaymentDetailsForm");
                  }}
                />
                <div className="mt-3  text-center">
                  <p className="px-3 m-auto">
                    Enter a {receiveInCurrency} ({receiveInNetwork})
                    wallet address where you will receive the funds 
                  </p>
                  <div className="my-5">
                    <div className="d-flex justify-content-between mb-0">
                      <label className="form-label mb-0" htmlFor="pay">
                        Enter wallet address
                      </label>
                      <small className="form-label mb-0">
                        {receiveInNetwork}
                      </small>
                    </div>
                    <textarea
                      className="form-control "
                      placeholder="Copy and paste address here"
                      rows="3"
                      value={receiveWalletAddress}
                      onChange={(e) => setReceiveWalletAddress(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="NB mb-4 m-auto">
                    Note: Ensure you enter the correct address entering a wrong
                    address may result in permanent loss
                  </div>

                  <div className="d-grid gap-2">
                    <Button
                      variant="primary"
                      className="py-2 btn-style"
                      size="md"
                      disabled={!receiveWalletAddress}
                      onClick={() => setActivePage("cryptoConfirmPurchase")}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={false} />
    </div>
  );
};

export default ReceiveCryptoPaymentDetailsForm;
