import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import logo from "../../images/Success.svg";
const LoginModal = ({ modal, setModalType }) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  });

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg login "
        show={modal}
        onHide={setModalType}
        centered
      >
        <Modal.Body className="d-flex py-5 justify-content-center text-center">
          <div>
            <img src={logo} alt="logo" height={150} />
            <h3 className="py-3">Login Successful</h3>

            <div className="d-grid gap-2 mt-5 mb-3">
              <Button
                variant="primary"
                className=" btn-style mt-4"
                size="md"
                onClick={() => window.location.reload()}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
