import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import copyIcon from "../images/copy.svg";

const OrderRequestsTable = ({ currentTableData }) => {
  const reloadPage = () => {
    window.location.reload();
  };

  const copyText = () => {
    toast.success("Copied successfully!");
  };

  return (
    <>
      {" "}
      {currentTableData && (
        <table
          className="table  display mb-4 dataTablesCard short-one card-table text-black text-left dataTable no-footer"
          id="transaction_table"
          role="grid"
          aria-describedby="example5_info"
        >
          <thead className="table-head-bg">
            <tr role="row">
              <th className="py-2-2" style={{ minWidth: 100 }}>
                Order Type
              </th>
              <th className="py-2-2" style={{ minWidth: 100 }}>
                {" "}
                Order Number
              </th>
              <th className="py-2-2" style={{ minWidth: 150 }}>
                Request Date
              </th>
              <th className="py-2-2" style={{ minWidth: 130 }}>
                Buy Currency
              </th>
              <th className="py-2-2" style={{ minWidth: 130 }}>
                Sell Currency
              </th>
              <th className="py-2-2" style={{ minWidth: 160 }}>
                App Name
              </th>
              <th className="py-2-2" style={{ minWidth: 270 }}>
                Status
              </th>
              <th className="py-2-2" style={{ minWidth: 100 }}>
                Action
                <i
                  className="fa fa-refresh ms-2 text-primary cursor-pointer"
                  aria-hidden="true"
                  onClick={() => reloadPage()}
                ></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentTableData?.map((item) => {
              return (
                <tr role="row" className="odd" key={item.orderReference}>
                  <td
                    className={`text-center ${
                      item.orderType === "Buy" && "success-green"
                    } ${item.orderType === "Sell" && "warning-red"} ${
                      item.orderType === "Swap" && "text-primary"
                    }`}
                  >
                    {item.orderType}
                  </td>
                  <td
                    style={{ width: "200px !important " }}
                    className="d-flex align-items-center"
                  >
                    <div className="data-tooltip" style={{ width: 100 }}>
                      {item.orderReference}
                    </div>

                    <CopyToClipboard
                      text={item.orderReference}
                      onCopy={() => copyText(item.orderReference)}
                    >
                      <img
                        src={copyIcon}
                        alt="copyIcon"
                        className="ml-2 cursor-pointer"
                      />
                    </CopyToClipboard>
                  </td>
                  <td>
                    {new Date(item.orderDateTimeStamp).toLocaleDateString()}
                    &nbsp;
                    {new Intl.DateTimeFormat("default", {
                      hour12: true,
                      hour: "numeric",
                      minute: "numeric",
                    }).format(item.orderDateTimeStamp)}
                  </td>
                  <td>
                    {item.receiveInCurrency}{" "}
                    {item?.customerPaymentDetails?.recieptMethod?.paymentData
                      ?.network !== "LOCAL" && (
                      <>
                        (
                        {
                          item?.customerPaymentDetails?.recieptMethod
                            ?.paymentData?.network
                        }
                        )
                      </>
                    )}
                  </td>
                  <td>
                    {item.payInCurrency}{" "}
                    {item?.customerPaymentDetails?.depositMethod?.paymentData
                      ?.network !== "LOCAL" && (
                      <>
                        (
                        {
                          item?.customerPaymentDetails?.depositMethod
                            ?.paymentData?.network
                        }
                        )
                      </>
                    )}
                  </td>
                  <td>{item?.thirdPartyAppName}</td>
                  <td>
                    {item.orderStatus === "Cancelled" && (
                      <span className="warning-red">Order Cancelled</span>
                    )}
                    {item.orderStatus === "Acknowledged" && (
                      <span className="secondary-blue">
                        Awaiting Payment From Customer
                      </span>
                    )}
                    {item.orderStatus === "FundedProvider" && (
                      <span className="secondary-blue">
                        Payment Received From Customer
                      </span>
                    )}
                    {item.orderStatus === "FundReceivedFromUser" && (
                      <span className="secondary-blue">
                        Customer Payment Confirmed
                      </span>
                    )}
                    {item.orderStatus === "FundedUser" && (
                      <span className="secondary-blue">Customer Credited</span>
                    )}
                    {item.orderStatus === "Completed" && (
                      <span className="success-green">Order Completed</span>
                    )}
                    {item.orderStatus === "OrderTimedOut" && (
                      <span className="warning-red">Order Expired</span>
                    )}
                  </td>
                  <td>
                    {item.orderStatus !== "OrderTimedOut" ||
                    item.orderStatus !== "Completed" ? (
                      <>
                        {item.orderStatus === "Acknowledged" ? (
                          <>
                            {item?.lpPaymentDetails?.paymentMethods
                              ?.orderPaymentMethod?.network === "LOCAL" && (
                              <a
                                href="/view-pending-request"
                                className="btn  btn-sm btn-info"
                              >
                                VIEW
                              </a>
                            )}
                            {item?.lpPaymentDetails?.paymentMethods
                              ?.orderPaymentMethod?.network !== "LOCAL" && (
                              <a
                                href="/pending-request"
                                className="btn  btn-sm btn-info"
                              >
                                VIEW
                              </a>
                            )}
                          </>
                        ) : (
                          <>
                            <a
                              href={`/view-order-request/${item?.orderReference}`}
                              className="btn  btn-sm btn-info"
                            >
                              VIEW
                            </a>
                          </>
                        )}
                      </>
                    ) : (
                      <a
                        href={`/view-order-request/${item?.orderReference}`}
                        className="btn  btn-sm btn-info"
                      >
                        VIEW
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default OrderRequestsTable;
