import React from "react";

const Filters = ({ getHistoryAcrossApps, handleDataFilter }) => {
  return (
    <div className="mb-3 d-flex justify-content-end">
      {" "}
      <div className="form-group mt-0 d-flex align-items-center gap-2">
        <p className="form-label mb-0 mr-3">Filter by:</p>

        <select
          class="form-select"
          aria-label="Default select example"
          onChange={(e) => handleDataFilter(e.target.value)}
          defaultValue={getHistoryAcrossApps}
          style={{
            width: "143px",
          }}
        >
          <option value={true}>All apps</option>{" "}
          <option value={false}>XendBridge lite</option>
        </select>
        {/* <select
          className="form-control custom-select"
          onChange={(e) => handleDataFilter(e.target.value)}
          defaultValue={getHistoryAcrossApps}
          style={{ width: "140px" }}
        >
          <option value={true}>All apps</option>{" "}
          <option value={false}>XendBridge lite</option>
        </select> */}
      </div>
    </div>
  );
};

export default Filters;
