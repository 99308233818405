import React from "react";

const CheckBox = ({ checked, key, onChange }) => {
  return (
    <label className={`container_`}>
      <input type="checkbox" checked={checked} key={key} onChange={onChange} />
      <span className="checkmark rounded me-2"></span>
    </label>
  );
};

export default CheckBox;
