import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import LoginSuccessModal from "./LoginSuccessModal";
import AppealModal from "./AppealModal";
import VerifySignupModal from "./VerifySignupModal";
import SignupFormModal from "./SignupFormModal";
import SignupSuccessModal from "./SignupSuccessModal";
import TransHashModal from "./TransHashModal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ResetPasswordModal from "./ResetPasswordModal";
import ResetPasswordSuccessModal from "./ResetPasswordSuccessModal";

function ModalComponent({
  modal,
  setModal,
  modalType,
  setModalType,
  modalTypeSell,
  emailOrPhone,
  setEmailOrPhone,
  verificationReference,
  setVerificationReference,
  setTransHash,
  transHash,
  onSubmit,
  setDisputeStatus,
  disputeStatus,
  orderReference,
  setOrderReference,
  disputeReference,
  setDisputeReference,
}) {
  return (
    <>
      {modalType === "Login" && (
        <LoginModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
        />
      )}
      {modalType === "Signup" && (
        <SignupModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
          setEmailOrPhone={setEmailOrPhone}
        />
      )}

      {modalType === "SignupForm" && (
        <SignupFormModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
          emailOrPhone={emailOrPhone}
          verificationReference={verificationReference}
        />
      )}

      {modalType === "ForgotPassword" && (
        <ForgotPasswordModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
          setEmailOrPhone={setEmailOrPhone}
        />
      )}

      {modalType === "ResetPasswordModal" && (
        <ResetPasswordModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
          emailOrPhone={emailOrPhone}
        />
      )}

      {modalType === "LoginSuccess" && (
        <LoginSuccessModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
        />
      )}

      {modalType === "SignupSuccess" && (
        <SignupSuccessModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
        />
      )}

      {modalType === "ResetPasswordSuccess" && (
        <ResetPasswordSuccessModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
        />
      )}

      {modalType === "VerifySignup" && (
        <VerifySignupModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
          emailOrPhone={emailOrPhone}
          setVerificationReference={setVerificationReference}
        />
      )}

      {modalType === "AppealModal" && (
        <AppealModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
          disputeStatus={disputeStatus}
          setDisputeStatus={setDisputeStatus}
          setOrderReference={setOrderReference}
          orderReference={orderReference}
          setDisputeReference={setDisputeReference}
          disputeReference={disputeReference}
        />
      )}

      {modalTypeSell === "TransHashModal" && (
        <TransHashModal
          modal={modal}
          setModal={setModal}
          setModalType={setModalType}
          transHash={transHash}
          setTransHash={setTransHash}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

export default ModalComponent;
