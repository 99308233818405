import React, { useState, useContext, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { GlobalContext } from '../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { getItemFromSessionStorage } from '../utils/fx';
import FiatInput from './Input/Fiat';
import CryptoInput from './Input/Crypto';
import { cryptoNetworks } from '../utils/cryptoNetworks';

const isLoggedIn = getItemFromSessionStorage('accessToken');

const BuyOrderForm = ({
  setActivePage,
  setModalType,
  setFiatValue,
  setCryptoValue,
  fiatValue,
  cryptoValue,
}) => {
  // Start Context
  const {
    getOrderRate,
    getBestRate,
    bestRate,
    getSupportedCurrencies,
    cryptoCurrencies,
    fiatCurrencies,
    fiatCurrency,
    fiatCurrencyImg,
    cryptoCurrency,
    cryptoCurrencyImg,
    setCryptoCurrencyImg,
    setCryptoCurrency,
    setFiatCurrencyImg,
    setFiatCurrency,
    setCryptoNetwork,
    setFiatNetwork,
    fiatNetwork,
    cryptoNetwork,
    startingRate,
    rateError,
    setCryptoNetworkImg,
    cryptoNetworkImg,
    setCryptoNetworkValue,
    cryptoNetworkValue,
  } = useContext(GlobalContext);
  // End Context

  console.log(cryptoCurrencies, 'cryptoCurrenciescryptoCurrencies');

  const [minAmountErrorMessage, setMinAmountErrorMessage] = useState(false);
  const [maxAmountErrorMessage, setMaxAmountErrorMessage] = useState(false);
  const [, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();

  useEffect(() => {
    getBestRate(
      cryptoCurrency,
      fiatCurrency,
      cryptoNetworkValue,
      fiatNetwork,
      'buy'
    );
    const intervalCall = setInterval(() => {
      getBestRate(
        cryptoCurrency,
        fiatCurrency,
        cryptoNetworkValue,
        fiatNetwork,
        'buy'
      );
    }, 20000); //20 seconds
    return () => {
      clearInterval(intervalCall);
    };
  }, [
    cryptoCurrency,
    cryptoNetworkValue,
    fiatCurrency,
    fiatNetwork,
    getBestRate,
  ]);

  useEffect(() => {
    getSupportedCurrencies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = async () => {
    setActivePage('buyPaymentDetailsForm');
  };

  const handleOrderRate = async (type, value) => {
    // Accepts only integer and decimal
    let formattedValue = value.replace(/[^0-9.]/gi, '');

    if (type === 'FIAT') {
      setFiatValue(formattedValue);
      try {
        let cryptoPayload = {
          payInCurrencyCode: cryptoCurrency,
          payInCurrencyNetwork: cryptoNetworkValue,
          receiveInCurrencyCode: fiatCurrency,
          receiveInCurrencyNetwork: fiatNetwork,
          orderAmount: formattedValue,
        };

        let response = await getOrderRate(cryptoPayload);

        setMaxAmount(response?.data?.maximumAmount);
        setMinAmount(response?.data?.minimumAmount);

        if (
          formattedValue / response?.data?.exchangeRate <
          response?.data?.minimumAmount
        ) {
          setMinAmountErrorMessage(true);
        } else if (
          formattedValue / response?.data?.exchangeRate >
          response?.data?.maximumAmount
        ) {
          setMaxAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
          setMaxAmountErrorMessage(false);
        }
        formattedValue !== ''
          ? setCryptoValue(formattedValue / response?.data?.exchangeRate)
          : setCryptoValue('');
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || 'Oops, Something went wrong');
      }
    } else {
      setCryptoValue(formattedValue);
      try {
        let fiatPayload = {
          payInCurrencyCode: fiatCurrency,
          payInCurrencyNetwork: fiatNetwork,
          receiveInCurrencyCode: cryptoCurrency,
          receiveInCurrencyNetwork: cryptoNetworkValue,
          orderAmount: formattedValue,
        };

        let response = await getOrderRate(fiatPayload);

        setMaxAmount(response?.data?.maximumAmount);
        setMinAmount(response?.data?.minimumAmount);

        formattedValue !== ''
          ? setFiatValue(response?.data?.exchangeRate * formattedValue)
          : setFiatValue('');
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || 'Oops, Something went wrong');
      }
    }
  };

  return (
    <div className="mt-3">
      <div>
        <label className="form-label mb-0" htmlFor="pay">
          You Pay
        </label>

        <FiatInput
          fiatValue={fiatValue}
          setMinAmountErrorMessage={setMinAmountErrorMessage}
          setMaxAmountErrorMessage={setMaxAmountErrorMessage}
          maxAmount={maxAmount}
          handleOrderRate={handleOrderRate}
          cryptoValue={cryptoValue}
          fiatCurrencies={fiatCurrencies}
          fiatCurrency={fiatCurrency}
          fiatCurrencyImg={fiatCurrencyImg}
          setFiatCurrency={setFiatCurrency}
          setFiatCurrencyImg={setFiatCurrencyImg}
          setFiatNetwork={setFiatNetwork}
          setFiatValue={setFiatValue}
        />
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <label className="form-label mb-0" htmlFor="pay">
            You Receive
          </label>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              as="div"
              className="  style-1 default-select text-dark-black d-flex align-items-center  p-2 me-1"
              style={{ fontSize: '12px' }}
            >
              {cryptoNetworkImg && (
                <img src={cryptoNetworkImg} alt="currencyImage" height={13} />
              )}
              &nbsp;&nbsp;{cryptoNetworkValue}
            </Dropdown.Toggle>{' '}
            <Dropdown.Menu>
              {cryptoNetworks?.map((item, i) => (
                <Dropdown.Item
                  style={{ fontSize: '12px', padding: ' 0.7rem 1.2rem' }}
                  onClick={() => {
                    setCryptoNetworkValue('');
                    setCryptoNetworkImg(item?.symbolUrl);
                    setCryptoNetworkValue(item?.name);
                  }}
                  key={i}
                >
                  {item?.symbolUrl && (
                    <>
                      <img
                        src={item?.symbolUrl}
                        alt="currencyImage"
                        height={13}
                      />
                      &nbsp;&nbsp;
                    </>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {item?.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <CryptoInput
          cryptoCurrencyImg={cryptoCurrencyImg}
          setCryptoCurrencyImg={setCryptoCurrencyImg}
          setCryptoCurrency={setCryptoCurrency}
          setCryptoNetwork={setCryptoNetwork}
          cryptoCurrencies={cryptoCurrencies}
          cryptoCurrency={cryptoCurrency}
          setMinAmountErrorMessage={setMinAmountErrorMessage}
          setMaxAmountErrorMessage={setMaxAmountErrorMessage}
          maxAmount={maxAmount}
          handleOrderRate={handleOrderRate}
          cryptoValue={cryptoValue}
          setCryptoValue={setCryptoValue}
          setCryptoNetworkValue={setCryptoNetworkValue}
          cryptoNetworkValue={cryptoNetworkValue}
          setCryptoNetworkImg={setCryptoNetworkImg}
          cryptoNetworkImg={cryptoNetworkImg}
        />
      </div>

      {minAmountErrorMessage && (
        <small className="text-danger d-block">
          *Minimum purchase amount: {1} {cryptoCurrency}*
        </small>
      )}

      {maxAmountErrorMessage && (
        <small className="text-danger">
          *Maximum purchase amount: {maxAmount} {cryptoCurrency}*
        </small>
      )}

      {rateError && <small className="text-danger">*{rateError}*</small>}

      <div className="d-flex justify-content-between">
        {' '}
        {startingRate && (
          <div className="rate ">
            <span>Starting Rate</span>
            <p>
              1 {cryptoCurrency} ~ {startingRate} {fiatCurrency}
            </p>
          </div>
        )}
        {bestRate && (
          <div className="rate ">
            <span>Best Rate</span>
            <p>
              1 {cryptoCurrency} ~ {bestRate} {fiatCurrency}
            </p>
          </div>
        )}
      </div>
      <div className="d-grid mt-4 gap-2">
        <Button
          variant="primary"
          className="py-2 btn-style"
          size="md"
          disabled={
            !fiatValue ||
            !cryptoValue ||
            cryptoValue < 1 ||
            cryptoValue > maxAmount
          }
          onClick={() => (isLoggedIn ? submitHandler() : setModalType('Login'))}
        >
          Buy
        </Button>
      </div>
    </div>
  );
};

export default BuyOrderForm;
