import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Footer from "./Footer";
import copyIcon from "../images/copy.svg";
import Appeal from "./Appeal";
import chat from "../images/Outline.svg";
import QRCode from "react-qr-code";
import { GlobalContext } from "../contexts/GlobalContext";
import { formatCurrency } from "../utils/fx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ModalComponent from "./Modals";
import SkeletonLoader from "./SkeletonLoader";
import arrow from "../images/arrow.svg";

const SellCryptoPendingDetails = ({
  setActivePage,
  setModalType,
  setOrderType,
  modalType,
  setDisputeStatus,
  setOrderReference,
  setDisputeReference,
}) => {
  let navigate = useNavigate();

  // Start Context
  const {
    bestRate,
    pendingOrder,
    getPendingOrder,
    confirmOrder,
    isPageLoading,
    cancelOrder,
  } = useContext(GlobalContext);
  // End Context

  const [transHash, setTransHash] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [modal] = useState(true);
  const [isCancelButtonLoading, setIsCancelButtonLoading] = useState(false);

  useEffect(() => {
    getPendingOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = async () => {
    setIsButtonLoading(true);
    try {
      let payload = {
        orderReference: pendingOrder?.orderResponse?.orderReference,
        transactionHash: transHash,
      };
      let response = await confirmOrder(payload);

      if (response?.data) {
        navigate(
          `/view-order-request/${pendingOrder?.orderResponse?.orderReference}`
        );
      } else {
        toast.error(response?.Message);
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || "Oops, Something went wrong");
    }
    setIsButtonLoading(false);
  };

  const copyText = () => {
    toast.success("Copied successfully!");
  };

  const cancelHandler = async () => {
    setIsCancelButtonLoading(true);
    try {
      let payload = {
        orderReference: pendingOrder?.orderResponse?.orderReference,
      };
      let response = await cancelOrder(payload);

      if (response?.data) {
        navigate(`/order-requests`);
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || "Oops, Something went wrong");
    }
    setIsCancelButtonLoading(false);
  };

  return (
    <>
      {/* Transaction hash modal */}
      <ModalComponent
        modal={modal}
        setModalType={setModalType}
        onSubmit={() => submitHandler()}
        transHash={transHash}
        setTransHash={setTransHash}
        modalTypeSell={modalType}
      />

      <div className="margin-top-xs  ">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <div>
                {pendingOrder?.orderResponse?.payInCurrencyCode && (
                  <p className="receiving-details title ps-1">
                    <a href="/order-requests" className="me-4">
                      <img src={arrow} alt="previous arrow" />
                    </a>
                    Sell {pendingOrder?.orderResponse?.payInCurrencyCode}
                  </p>
                )}

                <div className="order-card-lg p-4">
                  <div className="row ">
                    <div className="col-md-6 buycrypto">
                      {" "}
                      {isPageLoading ? (
                        <SkeletonLoader />
                      ) : (
                        <>
                          <div className="row ">
                            <div className="col-md-6">
                              <h6>When you pay</h6>
                              <p className="mb-1">
                                {formatCurrency(
                                  pendingOrder?.orderResponse?.payableAmount
                                )}{" "}
                                <span>
                                  {
                                    pendingOrder?.orderResponse
                                      ?.payInCurrencyCode
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="col-md-6">
                              <h6>You will receive</h6>
                              <p className="dark mb-1">
                                {" "}
                                {formatCurrency(
                                  pendingOrder?.orderResponse?.receivableAmount
                                )}{" "}
                                <span>
                                  {
                                    pendingOrder?.orderResponse
                                      ?.receiveInCurrencyCode
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                          {bestRate && (
                            <span className="rate">
                              1 {pendingOrder?.orderResponse?.payInCurrencyCode}{" "}
                              ~ {bestRate}{" "}
                              {
                                pendingOrder?.orderResponse
                                  ?.receiveInCurrencyCode
                              }
                            </span>
                          )}
                          <div className="bank-details p-3 mt-3">
                            <p className="title">
                              Send only{" "}
                              {formatCurrency(
                                pendingOrder?.orderResponse?.payableAmount
                              )}{" "}
                              {pendingOrder?.orderResponse?.payInCurrencyCode}{" "}
                              to this deposit address below.
                            </p>

                            <div className="row mt-4">
                              <div className="col-md-12 mb-3 d-flex align-items-end">
                                <div>
                                  {" "}
                                  <p className="head mb-0">
                                    {
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.network
                                    }{" "}
                                    Address
                                  </p>
                                  <div className="d-flex mb-1">
                                    <p className="subhead mb-0 me-1">
                                      {
                                        pendingOrder?.orderResponse
                                          ?.providerPaymentMethods
                                          ?.orderPaymentMethod?.walletAddress
                                      }
                                    </p>{" "}
                                    {pendingOrder?.orderResponse
                                      ?.providerPaymentMethods
                                      ?.orderPaymentMethod?.walletAddress && (
                                      <>
                                        <CopyToClipboard
                                          text={
                                            pendingOrder?.orderResponse
                                              ?.providerPaymentMethods
                                              ?.orderPaymentMethod
                                              ?.walletAddress
                                          }
                                          onCopy={() =>
                                            copyText(
                                              pendingOrder?.orderResponse
                                                ?.providerPaymentMethods
                                                ?.orderPaymentMethod
                                                ?.walletAddress
                                            )
                                          }
                                        >
                                          <img
                                            src={copyIcon}
                                            className="cursor-pointer"
                                            alt="copyIcon"
                                          />
                                        </CopyToClipboard>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {pendingOrder?.orderResponse
                                ?.providerPaymentMethods?.orderPaymentMethod
                                ?.walletAddress && (
                                <div className="col-md-3">
                                  <QRCode
                                    value={
                                      pendingOrder?.orderResponse
                                        ?.providerPaymentMethods
                                        ?.orderPaymentMethod?.walletAddress
                                    }
                                    size={90}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="row mt-3">
                              <div className="col-md-7">
                                <p className="head mb-0">Reference</p>
                                <div className="d-flex mb-1">
                                  <p className="subhead mb-0 me-1">
                                    {
                                      pendingOrder?.orderResponse
                                        ?.orderReference
                                    }
                                  </p>{" "}
                                  {pendingOrder?.orderResponse
                                    ?.orderReference && (
                                    <>
                                      <CopyToClipboard
                                        text={
                                          pendingOrder?.orderResponse
                                            ?.orderReference
                                        }
                                        onCopy={() =>
                                          copyText(
                                            pendingOrder?.orderResponse
                                              ?.providerPaymentMethods
                                              ?.orderPaymentMethod
                                              ?.walletAddress
                                          )
                                        }
                                      >
                                        <img
                                          src={copyIcon}
                                          className="cursor-pointer"
                                          alt="copyIcon"
                                        />
                                      </CopyToClipboard>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timing mt-4">
                            <p className="mb-0">
                              {" "}
                              Time remaining:{" "}
                              <Countdown
                                date={
                                  pendingOrder?.orderResponse
                                    ?.orderExpiryTimestamp
                                }
                                renderer={({ minutes, seconds }) => (
                                  <span className="text-danger">
                                    {" "}
                                    {minutes || "00"}:{seconds || "00"}
                                  </span>
                                )}
                              />
                            </p>
                            <p className="title">
                              Please make a payment within 15:00 mins,
                              otherwise, the order wil be cancelled
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              {" "}
                              {isButtonLoading ? (
                                <Button
                                  disabled
                                  variant="primary "
                                  className="py-2 btn-style"
                                  size="md"
                                >
                                  <i
                                    className="fa fa-spinner fa-pulse mx-5"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              ) : (
                                <button
                                  className="btn btn-primary py-2 btn-style me-3"
                                  size="md"
                                  onClick={() => setModalType("TransHashModal")}
                                >
                                  I have made deposit
                                </button>
                              )}
                              {isCancelButtonLoading ? (
                                <Button
                                  disabled
                                  variant="danger "
                                  className="py-2 btn-style"
                                  size="md"
                                >
                                  <i
                                    className="fa fa-spinner fa-pulse mx-5"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              ) : (
                                <button
                                  className="btn btn-danger py-2 btn-style"
                                  size="md"
                                  onClick={() => cancelHandler()}
                                >
                                  Cancel order
                                </button>
                              )}
                            </div>

                            <img
                              src={chat}
                              alt="chat"
                              className="me-1 cursor-pointer d-appeal"
                              onClick={() => {
                                setDisputeStatus(
                                  pendingOrder?.orderResponse?.disputeStatus
                                );
                                setOrderReference(
                                  pendingOrder?.orderResponse?.orderReference
                                );
                                setDisputeReference(
                                  pendingOrder?.orderResponse?.disputeReference
                                );
                                setModalType("AppealModal");
                              }}
                            />
                          </div>{" "}
                        </>
                      )}
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5 hide-appeal">
                      <Appeal
                        disputeStatus={
                          pendingOrder?.orderResponse?.disputeStatus
                        }
                        orderReference={
                          pendingOrder?.orderResponse?.orderReference
                        }
                        disputeReference={
                          pendingOrder?.orderResponse?.disputeReference
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer showTrans={false} />
      </div>
    </>
  );
};

export default SellCryptoPendingDetails;
