import React, { useEffect, useState } from "react";
import { FormControl, InputGroup, Dropdown } from "react-bootstrap";
import { cryptoNetworks } from "../../utils/cryptoNetworks";

const PayIn = ({
  payInValue,
  handleOrderRate,
  setMinAmountErrorMessage,
  setMaxAmountErrorMessage,
  maxAmount,
  setPayInCurrency,
  setPayInCurrencyImg,
  //   setCurrencyTypeErrorMessage,
  setCurrencyType,
  setPayInCurrencyType,
  setPayInValue,
  payInCurrency,
  payInCurrencyImg,
  supportedCurrencies,
  setPayInNetwork,
  receiveInCurrencyType,
  activeCurrencyType,
  setCryptoNetworkPayInValue,
  cryptoNetworkPayInImg,
  setCryptoNetworkPayInImg,
  cryptoNetworkPayInValue,
}) => {
  console.log(cryptoNetworkPayInValue)
  const [networkCryptoCurrencies, setNetworkCryptoCurrencies] = useState();

  useEffect(() => {
    let networkCryptoCurrencies_ = supportedCurrencies.filter((item) =>
      activeCurrencyType === "crypto"
        ? item.network === cryptoNetworkPayInValue
        : item.network === "LOCAL"
    );
    setNetworkCryptoCurrencies(networkCryptoCurrencies_);
    setPayInCurrency(networkCryptoCurrencies_[0]?.currencySymbol);
    setPayInCurrencyImg(networkCryptoCurrencies_[0]?.symbolUrl);
    setPayInNetwork(cryptoNetworkPayInValue)
  }, [cryptoNetworkPayInValue, supportedCurrencies]);

  return (
    <>
      {" "}
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Enter amount"
          className={`${
            activeCurrencyType === "crypto" ? "input-width-2" : "input-width"
          }`}
          value={payInValue}
          onChange={(e) => {
            handleOrderRate("PayIn", e.target.value);
            if (e.target.value < 1) {
              setMinAmountErrorMessage(true);
            } else if (e.target.value > maxAmount) {
              setMaxAmountErrorMessage(true);
            } else {
              setMinAmountErrorMessage(false);
              setMaxAmountErrorMessage(false);
            }
          }}
        />

        <Dropdown>
          <Dropdown.Toggle
            variant=""
            as="div"
            className={`form-control style-1 default-select text-dark-black d-flex align-items-center   p-2 ${
              activeCurrencyType === "crypto" && "pe-3"
            }`}
            style={{ fontSize: "12px" }}
          >
            {payInCurrencyImg && (
              <img src={payInCurrencyImg} alt="currencyImage" height={13} />
            )}
            &nbsp;&nbsp;{payInCurrency}
          </Dropdown.Toggle>{" "}
          <Dropdown.Menu>
            {networkCryptoCurrencies?.map((item, i) => (
              <Dropdown.Item
                style={{ fontSize: "13px", padding: " 0.7rem 1.2rem" }}
                onClick={() => {
                  setCurrencyType(item.currencyType);
                  setPayInCurrencyType(item.currencyType);
                  setPayInValue("");
                  setPayInCurrency(item?.currencySymbol);
                  setPayInCurrencyImg(item?.symbolUrl);
                  setPayInNetwork(item?.network );

                  //   if (item.currencyType !== receiveInCurrencyType) {
                  //     setCurrencyTypeErrorMessage(true);
                  //   } else {
                  //     setCurrencyTypeErrorMessage(false);
                  //   }
                }}
                key={i}
              >
                {item?.symbolUrl && (
                  <>
                    <img
                      src={item?.symbolUrl}
                      alt="currencyImage"
                      height={13}
                    />
                    &nbsp;&nbsp;
                  </>
                )}{" "}
                <strong>{item?.currencySymbol}</strong>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {item?.name}&nbsp;&nbsp;({item?.network})
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    </>
  );
};

export default PayIn;
