import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Footer from "./Footer";
import copyIcon from "../images/copy.svg";
import Appeal from "./Appeal";
import chat from "../images/Outline.svg";
import { GlobalContext } from "../contexts/GlobalContext";
import { formatCurrency } from "../utils/fx";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import SkeletonLoader from "./SkeletonLoader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";

const BuyCrypto = ({
  setActivePage,
  setModalType,
  setOrderType,
  cryptoValue,
  fiatValue,
  orderReference,
  orderAcctName,
  orderAcctNumber,
  orderBankName,
  orderPhoneNo,
  orderAcctId,
  fiatPaymentMethod,
  setDisputeStatus,
  setOrderReference,
  setDisputeReference,
}) => {
  let navigate = useNavigate();

  // Start Context
  const {
    bestRate,
    cryptoCurrency,
    fiatCurrency,
    confirmOrder,
    getPendingOrder,
    pendingOrder,
    isPageLoading,
    getNewPendingOrder,
    cancelOrder,
  } = useContext(GlobalContext);
  // End Context

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCancelButtonLoading, setIsCancelButtonLoading] = useState(false);

  useEffect(() => {
    getPendingOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNewPendingOrder();
    const intervalCall = setInterval(() => {
      getNewPendingOrder();
    }, 20000); //20 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = async () => {
    setIsButtonLoading(true);
    try {
      let payload = {
        emailAddress: pendingOrder?.orderResponse?.customerContact?.email,
        orderReference: pendingOrder?.orderResponse?.orderReference,
        transactionHash: "",
      };
      let response = await confirmOrder(payload);

      if (response?.data) {
        setOrderType("buy");
        setActivePage("orderSuccess");
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || "Oops, Something went wrong");
    }
    setIsButtonLoading(false);
  };

  const copyText = () => {
    toast.success("Copied successfully!");
  };

  const cancelHandler = async () => {
    setIsCancelButtonLoading(true);
    try {
      let payload = {
        orderReference: pendingOrder?.orderResponse?.orderReference,
      };
      let response = await cancelOrder(payload);

      if (response?.data) {
        navigate(`/order-requests`);
      }
    } catch ({ response }) {
      const { Message } = response?.data;
      toast.error(Message || "Oops, Something went wrong");
    }
    setIsCancelButtonLoading(false);
  };

  return (
    <div className="margin-top-xs  ">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div>
              {cryptoCurrency && (
                <p className="receiving-details title ms-2 ">
                  Buy {cryptoCurrency}
                </p>
              )}

              <div className="order-card-lg p-4">
                <div className="row">
                  <div className="col-md-6 buycrypto">
                    {isPageLoading ? (
                      <SkeletonLoader />
                    ) : (
                      <>
                        <div className="row ">
                          <div className="col-md-6">
                            <h6>When you pay</h6>
                            <p className="mb-1">
                              {formatCurrency(fiatValue)}{" "}
                              <span>{fiatCurrency}</span>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <h6>You will receive</h6>
                            <p className="dark mb-1">
                              {formatCurrency(cryptoValue)}{" "}
                              <span>{cryptoCurrency}</span>
                            </p>
                          </div>
                        </div>

                        {bestRate && (
                          <span className="rate">
                            1 {cryptoCurrency} ~ {bestRate} {fiatCurrency}
                          </span>
                        )}

                        <div className="bank-details p-3 mt-3">
                          <p className="title">
                            Send Money to the Merchant's Account details
                            provided below.
                          </p>
                          <div className="form-group">
                            <select className="form-control" disabled>
                              <option selected value="">
                                {fiatPaymentMethod}
                              </option>
                            </select>
                          </div>

                          <div className="row mt-4">
                            {orderBankName && (
                              <div className="col-md-4">
                                <p className="head mb-0">Bank</p>
                                <p className="subhead mb-1">{orderBankName}</p>
                              </div>
                            )}
                            <div className="col-md-7">
                              <p className="head mb-0">Account Name</p>
                              <div className="d-flex mb-1">
                                <p className="subhead mb-0 me-1">
                                  {orderAcctName}
                                </p>
                                <CopyToClipboard
                                  text={orderAcctName}
                                  onCopy={() => copyText()}
                                >
                                  <img
                                    src={copyIcon}
                                    alt="copyIcon"
                                    className="cursor-pointer"
                                  />
                                </CopyToClipboard>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {orderAcctNumber && (
                              <div className="col-md-4">
                                <p className="head mb-0">Account Number</p>
                                <div className="d-flex mb-1">
                                  <p className="subhead mb-0 me-1">
                                    {orderAcctNumber}{" "}
                                  </p>
                                  <CopyToClipboard
                                    text={orderAcctNumber}
                                    onCopy={() => copyText()}
                                  >
                                    <img
                                      src={copyIcon}
                                      alt="copyIcon"
                                      className="cursor-pointer"
                                    />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            )}

                            {orderAcctId && (
                              <div className="col-md-4">
                                <p className="head mb-0">Account ID</p>
                                <div className="d-flex mb-1">
                                  <p className="subhead mb-0 me-1">
                                    {orderAcctId}{" "}
                                  </p>{" "}
                                  <CopyToClipboard
                                    text={orderAcctNumber}
                                    onCopy={() => copyText()}
                                  >
                                    <img
                                      src={copyIcon}
                                      alt="copyIcon"
                                      className="cursor-pointer"
                                    />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            )}

                            {orderPhoneNo && (
                              <div className="col-md-4">
                                <p className="head mb-0">Phone No.</p>
                                <div className="d-flex align-items-center">
                                  <p className="subhead mb-0 me-1">
                                    {orderPhoneNo}
                                  </p>
                                  <CopyToClipboard
                                    text={orderPhoneNo}
                                    onCopy={() => copyText()}
                                  >
                                    <img
                                      src={copyIcon}
                                      alt="copyIcon"
                                      className="cursor-pointer"
                                    />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            )}

                            <div className="col-md-7">
                              <p className="head mb-0">Reference</p>
                              <div className="d-flex mb-1">
                                <p className="subhead mb-0 me-1">
                                  {orderReference}
                                </p>{" "}
                                <CopyToClipboard
                                  text={orderReference}
                                  onCopy={() => copyText()}
                                >
                                  <img
                                    src={copyIcon}
                                    alt="copyIcon"
                                    className="cursor-pointer"
                                  />
                                </CopyToClipboard>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="timing mt-4">
                          <p className="mb-0">
                            {" "}
                            Time remaining:{" "}
                            <Countdown
                              date={
                                pendingOrder?.orderResponse
                                  ?.orderExpiryTimestamp
                              }
                              renderer={({ minutes, seconds }) => (
                                <span className="text-danger">
                                  {" "}
                                  {minutes || "00"}:{seconds || "00"}
                                </span>
                              )}
                            />
                          </p>
                          <p className="title">
                            Please make a payment within 15:00 mins, otherwise,
                            the order will be cancelled
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {" "}
                            {isButtonLoading ? (
                              <Button
                                disabled
                                variant="primary "
                                className="py-2 btn-style"
                                size="md"
                              >
                                <i
                                  className="fa fa-spinner fa-pulse mx-5"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            ) : (
                              <button
                                className="btn btn-primary py-2 btn-style me-3"
                                size="md"
                                onClick={() => submitHandler()}
                              >
                                I have made transfer
                              </button>
                            )}
                            {isCancelButtonLoading ? (
                              <Button
                                disabled
                                variant="danger "
                                className="py-2 btn-style"
                                size="md"
                              >
                                <i
                                  className="fa fa-spinner fa-pulse mx-5"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            ) : (
                              <button
                                className="btn btn-danger py-2 btn-style"
                                size="md"
                                onClick={() => cancelHandler()}
                              >
                                Cancel order
                              </button>
                            )}
                          </div>
                          <img
                            src={chat}
                            alt="chat"
                            className="me-1 cursor-pointer d-appeal"
                            onClick={() => {
                              setDisputeStatus(
                                pendingOrder?.orderResponse?.disputeStatus
                              );
                              setOrderReference(
                                pendingOrder?.orderResponse?.orderReference
                              );
                              setDisputeReference(
                                pendingOrder?.orderResponse?.disputeReference
                              );
                              setModalType("AppealModal");
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-5 hide-appeal">
                    <Appeal
                      disputeStatus={pendingOrder?.orderResponse?.disputeStatus}
                      orderReference={
                        pendingOrder?.orderResponse?.orderReference
                      }
                      disputeReference={
                        pendingOrder?.orderResponse?.disputeReference
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showTrans={false} />
    </div>
  );
};

export default BuyCrypto;
