import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import logo from "../images/logo.svg";
import logo2 from "../images/logo3.svg";
import logo3 from "../images/logo3.svg";
import { getItemFromSessionStorage } from "../utils/fx";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";

const isLoggedIn = getItemFromSessionStorage("accessToken");

export default function Navbar({ setModalType }) {
  let location = useLocation();
  const { logoutCustomer } = useContext(AuthContext);
  const { ongoingTrans, getOrderRequests } = useContext(GlobalContext);

  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    getOrderRequests(1,true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav className=" navbar-dropdown navbar-dark fixed-top bg-color py-1 navbar navbar-expand-lg ">
        <div className="container py-4">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" height={30} />
          </a>
          <ul className="nav navbar-nav ml-auto">
            {!isLoggedIn ? (
              <li className="nav-item me-3">
                <a className="nav-link" href="mailto:Hello@Xendbridge.com">
                  Get Help
                </a>
              </li>
            ) : (
              <>
                {!ongoingTrans && (
                  <li className="nav-item me-3">
                    <a
                      className={`nav-link  ${
                        location?.pathname === "/" && "active"
                      }`}
                      href="/"
                    >
                      Home
                    </a>
                  </li>
                )}
                <li className="nav-item me-3">
                  <a
                    className={`nav-link  ${
                      location?.pathname === "/order-requests" && "active"
                    }`}
                    href="/order-requests"
                  >
                    Order Requests
                  </a>
                </li>
              </>
            )}
            <li className="nav-item me-3">
              {!isLoggedIn ? (
                <a
                  href="/#"
                  className="nav-link"
                  onClick={() => setModalType("Login")}
                >
                  Log In
                </a>
              ) : (
                <a
                  href="/#"
                  className="nav-link"
                  onClick={() => logoutCustomer()}
                >
                  Log Out
                </a>
              )}
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="mobile p-4">
        <div
          // className="burger"
          className={`burger ${toggleMenu ? "open" : ""}`}
          style={{ transform: "translate(0px, 0px)", opacity: 1 }}
          onClick={toggleNav}
        >
          <span>&nbsp;</span>
          <span className="dif">&nbsp;</span>
          <span>&nbsp;</span>
        </div>
        <a className="navbar-brand" href="#/">
          <img src={logo2} alt="logo" height={25} />
        </a>
        {toggleMenu && (
          <>
            <div
              className={`links ${toggleMenu ? "open" : ""}`}
              style={{ opacity: 1 }}
            >
              <a className="navbar-brand" href="#/">
                <img src={logo3} alt="logo" height={25} />
              </a>
              <div className="container  pl-5 pb-4 pt-4">
                <ul style={{ listStyle: "none" }} className="p-0">
                  {!isLoggedIn ? (
                    <li>
                      <a className="text-gray" href="/#">
                        Get Help
                      </a>
                    </li>
                  ) : (
                    <>
                      {!ongoingTrans && (
                        <li>
                          <a
                            className={`text-gray  ${
                              location?.pathname === "/" && "active"
                            }`}
                            href="/"
                          >
                            Home
                          </a>
                        </li>
                      )}
                      <li>
                        <a
                          className={`text-gray  ${
                            location?.pathname === "/order-requests" && "active"
                          }`}
                          href="/order-requests"
                        >
                          Order Requests
                        </a>
                      </li>
                    </>
                  )}

                  <li>
                    {!isLoggedIn ? (
                      <a
                        href="/#"
                        className="text-gray"
                        onClick={() => setModalType("Login")}
                      >
                        Log In
                      </a>
                    ) : (
                      <a
                        href="/#"
                        className="text-gray"
                        onClick={() => logoutCustomer()}
                      >
                        Log Out
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </nav>
    </>
  );
}
