import axiosInstance from "../utils/request";

// AUTHENTICATION
export const createGenerateOTP = async (payload) => {
  const { data } = await axiosInstance.post("api/OTP/Generate", payload);
  return data;
};

export const createValidateOTP = async (payload) => {
  const { data } = await axiosInstance.post("api/OTP/Validate", payload);
  return data;
};

export const createGenerateOTPPassword = async (payload) => {
  const { data } = await axiosInstance.post(
    "api/Password/Reset/Initiate",
    payload
  );
  return data;
};

export const createValidateOTPPassword = async (payload) => {
  const { data } = await axiosInstance.post(
    "api/Password/Reset/Complete",
    payload
  );
  return data;
};

export const createCustomer = async (payload) => {
  const { data } = await axiosInstance.post("api/Signup/Customer", payload);
  return data;
};

export const createLogin = async (payload) => {
  const { data } = await axiosInstance.post("api/Auth/Customer/Login", payload);

  if (data?.data) {
    sessionStorage.setItem(
      "accessToken",
      JSON.stringify(data?.data?.accessToken)
    );
    sessionStorage.setItem(
      "refreshToken",
      JSON.stringify(data?.data?.refreshToken)
    );
    sessionStorage.setItem("tokenExpires", JSON.stringify(data?.data?.expires));
    sessionStorage.setItem("userInfo", JSON.stringify(data?.data?.userInfo));
  }
  return data;
};

export const createGoogleAuth = async (payload) => {
  const { data } = await axiosInstance.post("api/Auth/Authenticate", payload);

  if (data?.data) {
    sessionStorage.setItem(
      "accessToken",
      JSON.stringify(data?.data?.accessToken)
    );
    sessionStorage.setItem(
      "refreshToken",
      JSON.stringify(data?.data?.refreshToken)
    );
    sessionStorage.setItem("tokenExpires", JSON.stringify(data?.data?.expires));
    sessionStorage.setItem("userInfo", JSON.stringify(data?.data?.userInfo));
  }
  return data;
};
// END AUTHENTICATION

// ORDERS
export const createSellOrder = async (payload) => {
  const { data } = await axiosInstance.post("api/Order/Sell/Initiate", payload);
  return data;
};

export const createSwapOrder = async (payload) => {
  const { data } = await axiosInstance.post("api/Order/Swap/Initiate", payload);
  return data;
};

export const createBuyOrder = async (payload) => {
  const { data } = await axiosInstance.post("api/Order/Buy/Initiate", payload);
  return data;
};

export const createConfirmOrderRequest = async (payload) => {
  const { data } = await axiosInstance.post("api/Order/Confirm", payload);
  return data;
};

export const createCancelOrderRequest = async (payload) => {
  const { data } = await axiosInstance.post("/api/Order/CancelOrder", payload);
  return data;
};

export const createRaiseOrderRequestDispute = async (payload) => {
  const { data } = await axiosInstance.post(
    "api/Dispute/RaiseDispute",
    payload
  );
  return data;
};
// END ORDERS
